<template>
  <v-container id="delivery-tour">
    <v-banner v-if="!geolocationIsEnabled && !closeGeolocationBanner" color="warning" icon="mdi-alert" dark outlined rounded class="my-2 ma-auto" style="max-width: 600px;">
      <div>
        <h1>{{ $t('deliverytour.banner.title') }}</h1>
        <br>
        <p v-for="content in $t('deliverytour.banner.content')" :key="content">
          {{ content }}
        </p>
        <div class="d-inline float-right">
          <v-btn color="secondary" @click="checkIfGeolocationIsEnabled('click')">
            {{ $t('deliverytour.button.geolocation.enabled') }}
          </v-btn>
        </div>
      </div>
    </v-banner>
    <div v-else-if="ordersInDelivery.length === 0 || showDeliveryTour" class="text-center">
      {{ ordersReady.length === 0 ? '' : ordersReady[0].location.name }}
      <br>
      <h1 class="pa-3 font-weight-light mt-4 mb-2 headline">
        {{ $t('deliverytour.instruction.ready', {for: readyFor}) }}
      </h1>
      <div v-if="!driverIsInLocation" class="pb-8 text-h4 red--text">
        {{ $t('deliverytour.instruction.mustGoBackToLocation') }}
      </div>
      <div class="mb-10 pb-8">
        <v-btn :loading="loading" :disabled="loading" color="primary" @click="planDeliveryTour">
          {{ $t('deliverytour.button.plan') }}
        </v-btn>
      </div>
      <v-dialog v-model="showDeliveryTour" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%"
                class="pa-2 elevation-0" content-class="elevation-0" @click:outside="startDeliveryTour()"
      >
        <base-material-card class="mt-6">
          <template #heading>
            <div class="display-2 font-weight-light text-uppercase">
              {{ $t('deliverytour.dialog.title') }}
            </div>
            <div class="subtitle-1 font-weight-light">
              {{ $t('deliverytour.dialog.subtitle') }}
            </div>
          </template>
          <v-container style="max-width: 600px">
            <div v-if="deliveryTour.instruction !== undefined">
              {{ $t('deliverytour.instruction.' + deliveryTour.instruction) }}
            </div>
            <div v-else-if="deliveryTour.orders === undefined || deliveryTour.orders.length === 0">
              {{ $t('deliverytour.instruction.empty') }}
            </div>
            <div v-else>
              <v-list flat subheader>
                <v-subheader>
                  {{ $tc('deliverytour.dialog.description', deliveryTour.orders.length, {toSelect: deliveryTour.orders.length - selectedOrders.length, total: deliveryTour.orders.length}) }}
                </v-subheader>
                <v-list-item-group v-model="selectedOrders" color="primary" multiple>
                  <v-list-item v-for="order in deliveryTour.orders" :key="order.id" :value="order.id" active-class="order-delivery-selected" @click="selectOrder(order.id)">
                    <template #default="{ active }">
                      <v-list-item-action>
                        <v-checkbox color="accent" :input-value="active" />
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title style="text-transform: uppercase;font-weight: bolder;">
                          {{ order.name }} <span v-if="getCity(order)">({{ getCity(order) }})</span>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="parseInt(order.totalPrice, 10) >= 50" class="red--text">
                          {{ $t('deliverytour.instruction.bigOrder') }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn v-if="deliveryTour.instruction !== undefined || deliveryTour.orders === undefined || deliveryTour.orders.length === 0" color="close" @click.stop="startDeliveryTour()">
              {{ $t('deliverytour.button.close') }}
            </v-btn>
            <v-btn v-else color="success" @click.stop="startDeliveryTour()">
              {{ $t('deliverytour.button.start') }}
            </v-btn>
          </v-card-actions>
        </base-material-card>
      </v-dialog>
    </div>
    <v-row v-else align="center">
      <v-col v-for="order in ordersInDelivery.slice(0, maxOrders)" :key="order.id" cols="12" sm="6" md="4" lg="3" xl="2" class="px-1 py-0">
        <order-card :order="order" @refresh="refresh" @error="error" />
      </v-col>
      <v-col v-if="maxOrders < ordersInDelivery.length" cols="12" sm="6" md="4" lg="3" xl="2" class="px-1 py-0 text-center">
        <v-btn v-if="$can('read', 'Order.Delivery.All')" icon x-large @click.stop="maxOrders++">
          <v-icon v-if="$isMobile()" dark>
            mdi-arrow-down
          </v-icon>
          <v-icon v-else dark>
            mdi-arrow-right
          </v-icon>
        </v-btn>
        <div v-else class="pb-10">
          <h3>{{ $t('deliverytour.nextOrders') }}</h3>
          <ul>
            <li v-for="order in ordersInDelivery.slice(1)" :key="order.id" style="text-transform:uppercase">
              {{ order.name }}
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import { Logger } from '@/services/common/Logger';
  import { secondToHHmm } from '@/util/DateUtil';
  import OrderState from '@/services/order/OrderState';
  import OrderCard from '@/views/components/business/order/OrderCard';
  import { getGeolocation, geolocationIsAllowed } from '@/util/DeviceUtil';
  import { DateTime } from 'luxon';
  import { getDistance } from 'geolib';

  export default {
    name: 'OrderDeliveryTour',
    components: { OrderCard },
    props: {
      orders: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        deliveryTour: { instruction: undefined, orders: [] },
        geolocationIsEnabled: true,
        closeGeolocationBanner: false,
        loading: false,
        maxOrders: 1,
        ordersReady: [],
        ordersInDelivery: [],
        selectedOrders: [],
        showDeliveryTour: false,
        driverIsInLocation: true
      };
    },
    computed: {
      readyFor () {
        const firstOrderUpdate = this.orders.reduce((minDate, order) => {
          if (order.state === undefined) {
            return minDate;
          }

          return minDate === undefined || order.state.createdAt < minDate ? order.state.createdAt : minDate;
        }, undefined);
        const firstReadyAt = DateTime.fromISO(firstOrderUpdate, { setZone: true }).setZone('UTC');
        return secondToHHmm(firstReadyAt.isValid ? firstReadyAt.diffNow('seconds').seconds : 0);
      }
    },
    watch: {
      orders: function () {
        this.classifyOrders();
        this.checkIfGeolocationIsEnabled();
      }
    },
    mounted: function () {
      this.classifyOrders();
      this.checkIfGeolocationIsEnabled('init');
    },
    methods: {
      isDriverInLocation (coordinates) {
        if (coordinates === undefined) {
          return;
        }
        const location = this.$store.state.location;
        if (typeof location !== 'object') {
          return;
        }
        const distanceInMeters = Math.abs(getDistance({ latitude: location.latitude, longitude: location.longitude }, { latitude: coordinates.latitude, longitude: coordinates.longitude }));
        this.driverIsInLocation = distanceInMeters < 500;
        if (!this.driverIsInLocation) {
          Logger.info('delivery tour: driver is not in location', { distanceInMeters: distanceInMeters });
        }
      },
      classifyOrders () {
        this.ordersReady = this.orders.filter((order) => order.state !== undefined && order.state.state === OrderState.ready);
        this.ordersInDelivery = this.orders.filter((order) => order.state !== undefined && [OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state));
      },
      checkIfGeolocationIsEnabled (action) {
        if (action === 'init' || !this.geolocationIsEnabled) {
          geolocationIsAllowed().then((isAllowed) => {
            if (action === 'click') {
              if (!isAllowed) {
                alert(this.$t('deliverytour.banner.content').join('\n'));
              }
            }
            this.geolocationIsEnabled = isAllowed;
          }).catch(Logger.exception);
        }
      },
      getCity (order) {
        if (order.actions.delivery !== undefined && order.actions.delivery.address !== undefined) {
          if (order.actions.delivery.address.city !== undefined) {
            return order.actions.delivery.address.city;
          } else if (order.actions.delivery.address.zip !== undefined) {
            return order.actions.delivery.address.zip;
          }
        }

        return '';
      },
      async planDeliveryTour () {
        if (this.loading) {
          return false;
        }

        try {
          const coordinates = await getGeolocation();
          this.isDriverInLocation(coordinates);
        } catch (error) {
          Logger.exception(error);
        }

        this.loading = true;
        try {
          const response = await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'orders/deliverytour/');
          this.deliveryTour = response.data;
          this.showDeliveryTour = true;
          this.error('');
        } catch (error) {
          this.error(await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('deliverytour.error.fail')));
        }
        this.loading = false;
      },
      selectOrder (orderId) {
        const selected = this.selectedOrders.length + (this.selectedOrders.includes(orderId) ? -1 : 1);
        if (this.deliveryTour.orders.length === selected) {
          // this.startDeliveryTour();
        }
      },
      startDeliveryTour () {
        this.refresh(undefined);
        this.selectedOrders = [];
        this.showDeliveryTour = false;
      },
      refresh (orderId) {
        this.$emit('refresh', orderId);
      },
      error (message) {
        this.$emit('error', message);
      }
    }
  };
</script>
