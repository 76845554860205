<template>
  <v-container id="recurrent-customers-platform" fluid tag="section">
    <v-row>
      <div class="pa-2">
        <div class="text-h3">
          {{ $t('customerService.recurrentCustomers.subtitle') }}
        </div>
        <div v-for="content in $t('customerService.recurrentCustomers.description')" :key="content">
          {{ content }}
        </div>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.recurrentCustomers.table.title') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="customers" :loading="loading" :hide-default-footer="true">
            <template #[`item.provider`]="{ item }">
              <img :src="require('@/assets/provider/' + item.provider + '.png')" width="24px" style="vertical-align:middle">
              {{ $t('order.tab.details.provider.' + item.provider) }}
            </template>
            <template #[`item.phone`]="{ item }">
              <span v-html="formatPhone(item.phone)" />
            </template>
            <template #[`item.lastOrderState`]="{ item }">
              {{ $t('order.tab.details.state.' + item.lastOrderState) }}
            </template>
            <template #[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template #[`item.action`]="{ item }">
              <v-btn small rounded color="primary" :loading="loading" @click.stop="callRecurrentCustomer(item)">
                <v-icon medium dark>
                  mdi-check
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'RecurrentCustomers',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        customers: [],
        loading: false,
        headers: [
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'locationName'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.provider'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'provider'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.firstName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'firstName'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.lastName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'lastName'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.phone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'phone'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.count'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'count'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.lastOrderState'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'lastOrderState'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'date'
          },
          {
            text: this.$i18n.t('customerService.recurrentCustomers.table.action'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'action'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['date'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatPhone (phone) {
        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'customers/recurrent/platform');
          if (response.data !== undefined) {
            this.customers = response.data.customers;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.$emit('count', this.customers.length);
      },
      async callRecurrentCustomer (recurrentCustomer) {
        if (confirm(this.$i18n.t('customerService.recurrentCustomers.table.confirm', { phone: recurrentCustomer.phone }))) {
          this.loading = true;
          try {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/recurrent/call?phone=' + String(recurrentCustomer.phone));
            this.$emit('success');
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
          }
          this.loading = false;
          await this.refreshData();
        }
      }
    }
  };
</script>
