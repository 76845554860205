<template>
  <v-container id="late-orders" fluid tag="section">
    <div class="pa-2">
      <div class="text-h3">
        {{ $t('customerService.lateOrders.subtitle') }}
      </div>
      <div v-for="content in $t('customerService.lateOrders.description')" :key="content">
        {{ content }}
      </div>
    </div>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.lateOrders.table.todo.title') }}
          </v-card-title>
          <v-data-table :headers="todoHeaders" :options="tableOptions" :items="todoOrders" :loading="loading" :hide-default-footer="true">
            <template #[`item.name`]="{ item }">
              <span v-html="displayOrderName(item)" />
            </template>
            <template #[`item.customerName`]="{ item }">
              {{ item.customer.firstName }} {{ item.customer.lastName }}
            </template>
            <template #[`item.customerPhone`]="{ item }">
              <span v-html="formatPhone(item.customer.phone)" />
            </template>
            <template #[`item.customerPhone2`]="{ item }">
              <span v-html="formatPhone(item.customer.phone2)" />
            </template>
            <template #[`item.driver`]="{ item }">
              <span v-html="displayDriver(item)" />
            </template>
            <template #[`item.delay`]="{ item }">
              <span v-html="displayDelay(item)" />
            </template>
            <template #[`item.state`]="{ item }">
              <span v-html="displayState(item)" />
            </template>
            <template #[`item.action`]="{ item }">
              <v-btn small rounded color="primary" :loading="loading" @click.stop="callLateOrderCustomer(item)">
                <v-icon medium dark>
                  mdi-check
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.lateOrders.table.done.title') }}
          </v-card-title>
          <v-data-table :headers="doneHeaders" :options="tableOptions" :items="doneOrders" :loading="loading" :hide-default-footer="true">
            <template #[`item.name`]="{ item }">
              <span v-html="displayOrderName(item)" />
            </template>
            <template #[`item.customerName`]="{ item }">
              {{ item.customer.firstName }} {{ item.customer.lastName }}
            </template>
            <template #[`item.customerPhone`]="{ item }">
              <span v-html="formatPhone(item.customer.phone)" />
            </template>
            <template #[`item.customerPhone2`]="{ item }">
              <span v-html="formatPhone(item.customer.phone2)" />
            </template>
            <template #[`item.driver`]="{ item }">
              <span v-html="displayDriver(item)" />
            </template>
            <template #[`item.delay`]="{ item }">
              <span v-html="displayDelay(item)" />
            </template>
            <template #[`item.state`]="{ item }">
              <span v-html="displayState(item)" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--    <div class="py-5">-->
    <!--      <h1>Toutes les commandes en retard</h1>-->
    <!--    </div>-->
    <!--    <orders-view :header="false" :orders="todoOrders.concat(doneOrders)" :last-update="lastUpdate" :error="error"-->
    <!--                 :no-result-title="$t('order.list.empty.title')" :no-result-description="$t('order.list.empty.description')"-->
    <!--                 mode="list" :show-order-counter="false" @refresh="refreshOrders"-->
    <!--    />-->
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import { displayHourISO, secondToHHmm } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import OrderState from '@/services/order/OrderState';
  import { DateTime } from 'luxon';

  export default {
    name: 'LateOrders',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        error: '',
        lastUpdate: 0,
        todoOrders: [],
        doneOrders: [],
        todoHeaders: [
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'location.name'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.orderName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.customerName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerName'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.customerPhone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerPhone'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.customerPhone2'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerPhone2'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.driver'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'driver'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.delay'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'delay'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.state'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'state'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.todo.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          }
        ],
        doneHeaders: [
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'location.name'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.orderName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.customerName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerName'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.customerPhone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerPhone'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.customerPhone2'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerPhone2'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.driver'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'driver'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.delay'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'delay'
          },
          {
            text: this.$i18n.t('customerService.lateOrders.table.done.state'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'state'
          }
        ],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshOrders();
      }
    },
    methods: {
      displayOrderName (order) {
        if (typeof order.name !== 'string') {
          return '';
        }

        return '<a href="' + this.$router.resolve('/search').href + '?name=' + encodeURIComponent(order.name) + '">' + order.name.toUpperCase() + '</a>';
      },
      formatPhone (phone) {
        if (phone === undefined) {
          return '';
        }

        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      displayDriver (order) {
        if (![OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state) || order.state.user === undefined) {
          return '';
        }

        if (order.state.user.phoneNumber === undefined) {
          return order.state.user.firstName;
        }

        return '<a href="tel:' + phoneInt(order.state.user.phoneNumber) + '" title="' + displayPhone(order.state.user.phoneNumber) + '">' + order.state.user.firstName + '</a>';
      },
      displayDelay (order) {
        const targetDate = order.actions?.delivery?.date;
        const now = DateTime.utc().toSeconds();
        const target = DateTime.fromISO(targetDate).setZone('UTC').plus({ minutes: 30 }).toSeconds();
        const timeLeftInSeconds = target - now;

        let color = 'green';
        let sign = '+';
        if (timeLeftInSeconds < -59) {
          color = 'red';
          sign = '-';
        } else if (timeLeftInSeconds > -60 && timeLeftInSeconds < 60) {
          color = 'orange';
          sign = '';
        }
        return '<span class="v-chip theme--light v-size--default ' + color + '">' + sign + secondToHHmm(timeLeftInSeconds) + '</span>';
      },
      displayState (order) {
        return this.$i18n.t('customerService.lateOrders.state.' + order.state.state) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(order.state.createdAt);
      },
      async refreshOrders () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/late');
          if (response.data !== undefined) {
            this.todoOrders = response.data.todo;
            this.doneOrders = response.data.done;
          }
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('order.error'));
        }
        this.$emit('count', this.todoOrders.length);
        this.loading = false;
      },
      async callLateOrderCustomer (lateOrder) {
        if (confirm(this.$i18n.t('customerService.lateOrders.table.todo.confirm', { name: lateOrder.customer?.firstName }))) {
          this.loading = true;
          try {
            await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'customer/late/order/call', {
              orderId: lateOrder.id
            });
            this.$emit('success');
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
          }
          this.loading = false;
          await this.refreshOrders();
        }
      }
    }
  };
</script>
