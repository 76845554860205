<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.editOrderCustomer.title') }} {{ order.name }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.editOrderCustomer.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && editOrderCustomer()">
          <v-container class="edit-order-customer">
            <v-card color="editOrderCustomerTabBackgroundColor" class="main-card">
              <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows height="30">
                <v-tab>{{ $t('order.form.editOrderCustomer.tabs.contact') }}</v-tab>
                <v-tab>{{ $t('order.form.editOrderCustomer.tabs.address') }}</v-tab>
                <v-tab-item key="1">
                  <v-card color="editOrderCustomerTabBackgroundColor" class="tab-card">
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="firstName" :counter="firstNameMaxLength" required :rules="firstNameRules"
                                      :label="$t('order.form.editOrderCustomer.input.firstName.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="lastName" :counter="lastNameMaxLength" required :rules="lastNameRules"
                                      :label="$t('order.form.editOrderCustomer.input.lastName.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="company" :counter="companyMaxLength" :rules="companyRules" :label="$t('order.form.editOrderCustomer.input.company.label')" :clearable="true" />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="phone" :rules="phoneRules" :label="$t('order.form.editOrderCustomer.input.phone.label')" :clearable="true" />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="phone2" :rules="phone2Rules" :disabled="typeof phone !== 'string' || phone.length === 0" :label="$t('order.form.editOrderCustomer.input.phone2.label')" :clearable="true" />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="email" :counter="emailMaxLength" :rules="emailRules" :label="$t('order.form.editOrderCustomer.input.email.label')" :clearable="true" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox v-model="acceptCallMarketing" :label="$t('order.form.editOrderCustomer.input.acceptCallMarketing.label')" color="accent" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox v-model="acceptSmsMarketing" :label="$t('order.form.editOrderCustomer.input.acceptSmsMarketing.label')" color="accent" />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-checkbox v-model="acceptEmailMarketing" :label="$t('order.form.editOrderCustomer.input.acceptEmailMarketing.label')" color="accent" />
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
                <v-tab-item key="2">
                  <v-card color="editOrderCustomerTabBackgroundColor" class="tab-card">
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-btn-toggle v-model="actionType" borderless dark dense mandatory>
                          <v-btn value="delivery">
                            {{ $t('order.form.editOrderCustomer.input.actionType.delivery') }}
                          </v-btn>
                          <v-btn value="pickup">
                            {{ $t('order.form.editOrderCustomer.input.actionType.pickup') }}
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="address1" :counter="address1MaxLength" required :rules="address1Rules"
                                      :label="$t('order.form.editOrderCustomer.input.address1.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field v-model="address2" :counter="address2MaxLength" required :rules="address2Rules"
                                      :label="$t('order.form.editOrderCustomer.input.address2.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="zip" :counter="zipMaxLength" required :rules="zipRules"
                                      :label="$t('order.form.editOrderCustomer.input.zip.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="city" :counter="cityMaxLength" required :rules="cityRules"
                                      :label="$t('order.form.editOrderCustomer.input.city.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field v-model="country" :counter="countryMaxLength" required :rules="countryRules"
                                      :label="$t('order.form.editOrderCustomer.input.country.label')" :clearable="true"
                        />
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <span class="px-2">
                          {{ $t('order.form.editOrderCustomer.input.latitude.label') }}
                          {{ latitude }}
                        </span>
                        |
                        <span class="px-2">
                          {{ $t('order.form.editOrderCustomer.input.longitude.label') }}
                          {{ longitude }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('order.form.editOrderCustomer.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && editOrderCustomer()">
              {{ $t('order.form.editOrderCustomer.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { isValidEmail, isValidPhoneNumber } from '@/util/ValidationUtil';

  export default {
    name: 'EditOrderCustomerForm',
    props: {
      order: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        firstName: this.order.customer.firstName,
        firstNameMinLength: 1,
        firstNameMaxLength: 30,
        firstNameRules: [
          v => !v || (v && v.length >= this.firstNameMinLength) || this.$t('order.form.editOrderCustomer.input.firstName.rule.minLength', { count: this.firstNameMinLength }),
          v => !v || (v && v.length <= this.firstNameMaxLength) || this.$t('order.form.editOrderCustomer.input.firstName.rule.maxLength', { count: this.firstNameMaxLength })
        ],
        lastName: this.order.customer.lastName,
        lastNameMinLength: 1,
        lastNameMaxLength: 30,
        lastNameRules: [
          v => !v || (v && v.length >= this.lastNameMinLength) || this.$t('order.form.editOrderCustomer.input.lastName.rule.minLength', { count: this.lastNameMinLength }),
          v => !v || (v && v.length <= this.lastNameMaxLength) || this.$t('order.form.editOrderCustomer.input.lastName.rule.maxLength', { count: this.lastNameMaxLength })
        ],
        company: this.order.customer.company,
        companyMinLength: 1,
        companyMaxLength: 80,
        companyRules: [
          v => !v || (v && v.length >= this.companyMinLength) || this.$t('order.form.editOrderCustomer.input.company.rule.minLength', { count: this.companyMinLength }),
          v => !v || (v && v.length <= this.companyMaxLength) || this.$t('order.form.editOrderCustomer.input.company.rule.maxLength', { count: this.companyMaxLength })
        ],
        phone: this.order.customer.phone,
        phoneMinLength: 10,
        phoneMaxLength: 30,
        phoneRules: [
          v => !v || (v && v.length >= this.phoneMinLength) || this.$t('order.form.editOrderCustomer.input.phone.rule.minLength', { count: this.phoneMinLength }),
          v => !v || (v && v.length <= this.phoneMaxLength) || this.$t('order.form.editOrderCustomer.input.phone.rule.maxLength', { count: this.phoneMaxLength }),
          v => !v || (v && isValidPhoneNumber(v)) || this.$t('order.form.editOrderCustomer.input.phone.rule.valid')
        ],
        phone2: this.order.customer.phone2,
        phone2MinLength: 10,
        phone2MaxLength: 30,
        phone2Rules: [
          v => !v || (v && v.length >= this.phone2MinLength) || this.$t('order.form.editOrderCustomer.input.phone2.rule.minLength', { count: this.phone2MinLength }),
          v => !v || (v && v.length <= this.phone2MaxLength) || this.$t('order.form.editOrderCustomer.input.phone2.rule.maxLength', { count: this.phone2MaxLength }),
          v => !v || (v && isValidPhoneNumber(v)) || this.$t('order.form.editOrderCustomer.input.phone2.rule.valid')
        ],
        email: this.order.customer.email,
        emailMinLength: 5,
        emailMaxLength: 350,
        emailRules: [
          v => !v || (v && v.length >= this.emailMinLength) || this.$t('order.form.editOrderCustomer.input.email.rule.minLength', { count: this.emailMinLength }),
          v => !v || (v && v.length <= this.emailMaxLength) || this.$t('order.form.editOrderCustomer.input.email.rule.maxLength', { count: this.emailMaxLength }),
          v => !v || (v && isValidEmail(v)) || this.$t('order.form.editOrderCustomer.input.email.rule.valid')
        ],
        actionType: this.order.actions.delivery !== undefined ? 'delivery' : (this.order.actions.pickup !== undefined ? 'pickup' : null),
        address1: this.order.actions.delivery?.address?.address1 || this.order.actions.pickup?.address?.address1,
        address1MinLength: 1,
        address1MaxLength: 200,
        address1Rules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.address1.rule.required'),
          v => (v && v.length >= this.address1MinLength) || this.$t('order.form.editOrderCustomer.input.address1.rule.minLength', { count: this.address1MinLength }),
          v => (v && v.length <= this.address1MaxLength) || this.$t('order.form.editOrderCustomer.input.address1.rule.maxLength', { count: this.address1MaxLength })
        ],
        address2: this.order.actions.delivery?.address?.address2 || this.order.actions.pickup?.address?.address2,
        address2MinLength: 1,
        address2MaxLength: 200,
        address2Rules: [
          v => !v || (v && v.length >= this.address2MinLength) || this.$t('order.form.editOrderCustomer.input.address2.rule.minLength', { count: this.address2MinLength }),
          v => !v || (v && v.length <= this.address2MaxLength) || this.$t('order.form.editOrderCustomer.input.address2.rule.maxLength', { count: this.address2MaxLength })
        ],
        zip: this.order.actions.delivery?.address?.zip || this.order.actions.pickup?.address?.zip,
        zipMinLength: 1,
        zipMaxLength: 10,
        zipRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.zip.rule.required'),
          v => (v && v.length >= this.zipMinLength) || this.$t('order.form.editOrderCustomer.input.zip.rule.minLength', { count: this.zipMinLength }),
          v => (v && v.length <= this.zipMaxLength) || this.$t('order.form.editOrderCustomer.input.zip.rule.maxLength', { count: this.zipMaxLength })
        ],
        city: this.order.actions.delivery?.address?.city || this.order.actions.pickup?.address?.city,
        cityMinLength: 1,
        cityMaxLength: 50,
        cityRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.city.rule.required'),
          v => (v && v.length >= this.cityMinLength) || this.$t('order.form.editOrderCustomer.input.city.rule.minLength', { count: this.cityMinLength }),
          v => (v && v.length <= this.cityMaxLength) || this.$t('order.form.editOrderCustomer.input.city.rule.maxLength', { count: this.cityMaxLength })
        ],
        country: this.order.actions.delivery?.address?.country || this.order.actions.pickup?.address?.country,
        countryMinLength: 1,
        countryMaxLength: 50,
        countryRules: [
          v => !!v || this.$t('order.form.editOrderCustomer.input.country.rule.required'),
          v => (v && v.length >= this.countryMinLength) || this.$t('order.form.editOrderCustomer.input.country.rule.minLength', { count: this.countryMinLength }),
          v => (v && v.length <= this.countryMaxLength) || this.$t('order.form.editOrderCustomer.input.country.rule.maxLength', { count: this.countryMaxLength })
        ],
        latitude: this.order.actions.delivery?.address?.latitude || this.order.actions.pickup?.address?.latitude,
        longitude: this.order.actions.delivery?.address?.longitude || this.order.actions.pickup?.address?.longitude,
        acceptCallMarketing: this.order.customer.acceptCallMarketing,
        acceptSmsMarketing: this.order.customer.acceptSmsMarketing,
        acceptEmailMarketing: this.order.customer.acceptEmailMarketing,
        valid: true,
        loading: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      async editOrderCustomer () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order',
                               {
                                 orderId: this.order.id,
                                 firstName: (this.firstName === null ? '' : this.firstName),
                                 lastName: (this.lastName === null ? '' : this.lastName),
                                 company: (this.company === null ? '' : this.company),
                                 phone: (this.phone === null ? '' : this.phone),
                                 phone2: (this.phone2 === null ? '' : this.phone2),
                                 email: (this.email === null ? '' : this.email),
                                 acceptCallMarketing: (this.acceptCallMarketing === null ? '' : this.acceptCallMarketing),
                                 acceptSmsMarketing: (this.acceptSmsMarketing === null ? '' : this.acceptSmsMarketing),
                                 acceptEmailMarketing: (this.acceptEmailMarketing === null ? '' : this.acceptEmailMarketing),
                                 actionType: (this.actionType === null ? '' : this.actionType),
                                 address1: (this.address1 === null ? '' : this.address1),
                                 address2: (this.address2 === null ? '' : this.address2),
                                 zip: (this.zip === null ? '' : this.zip),
                                 city: (this.city === null ? '' : this.city),
                                 country: (this.country === null ? '' : this.country)
                               });
          this.$emit('success', this.order.id);
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
