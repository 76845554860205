<template>
  <div v-if="![OrderState.created, OrderState.payment, OrderState.review, OrderState.placed, OrderState.done, OrderState.failed, OrderState.cancelled].includes(order.state.state)">
    <v-icon left>
      mdi-timer-sand
    </v-icon>{{ formattedTimer }}
  </div>
</template>
<script>
  import { DateTime } from 'luxon';
  import { secondToHHmm } from '@/util/DateUtil';
  import OrderState from '@/services/order/OrderState';

  export default {
    name: 'OrderTimer',
    props: {
      order: {
        type: Object,
        required: true
      },
      refresh: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        OrderState: OrderState,
        timerInSeconds: 0
      };
    },
    computed: {
      formattedTimer () {
        return secondToHHmm(this.timerInSeconds);
      }
    },
    watch: {
      refresh: function () {
        let state;
        let i = this.order.states.length - 1;
        while (i >= 0 && state === undefined) {
          if ([OrderState.preparation, OrderState.ready, OrderState.delivery].includes(this.order.states[i].state)) {
            state = this.order.states[i];
          }
          i--;
        }
        if (state !== undefined) {
          const now = DateTime.utc().toSeconds();
          const lastUpdate = DateTime.fromISO(state.createdAt).setZone('UTC').toSeconds();
          this.timerInSeconds = now - lastUpdate;
        }
      }
    }
  };
</script>
