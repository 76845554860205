var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"orders-abandoned","fluid":"","tag":"section"}},[_c('v-row',[_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.$t('customerService.ordersAbandoned.subtitle'))+" ")]),_vm._l((_vm.$t('customerService.ordersAbandoned.description')),function(content){return _c('div',{key:content},[_vm._v(" "+_vm._s(content)+" ")])})],2)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.ordersAbandoned.table.title1'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers1,"options":_vm.options,"items":_vm.ordersAbandoned,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require('@/assets/provider/' + item.provider + '.png'),"width":"24px"}}),_vm._v(" "+_vm._s(_vm.$t('order.tab.details.provider.' + item.provider))+" ")]}},{key:"item.customerPhone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.customerPhone))}})]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.ordersAbandoned.table.reasons.' + item.reason))+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('customerService.ordersAbandoned.button.pay')))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('close-order-abandoned-form',{attrs:{"order-abandoned":item},on:{"success":_vm.success,"fail":_vm.fail}})]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.ordersAbandoned.table.title2'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers2,"options":_vm.options,"items":_vm.closedOrdersAbandoned,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require('@/assets/provider/' + item.provider + '.png'),"width":"24px"}}),_vm._v(" "+_vm._s(_vm.$t('order.tab.details.provider.' + item.provider))+" ")]}},{key:"item.reason",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.ordersAbandoned.table.reasons.' + item.reason))+" ")]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [(item.link)?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('customerService.ordersAbandoned.button.pay')))]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.updatedAt))+" ")]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }