<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('customerService.nextOrders.subtitle') }}
            <v-spacer />
            <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('customerService.nextOrders.table.search')" single-line hide-details />
            <v-spacer />
            <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshData">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="orders" :search="search" :loading="loading" hide-default-footer>
            <template #[`item.provider`]="{ item }">
              <span>{{ formatProvider(item.provider) }}</span>
            </template>
            <template #[`item.name`]="{ item }">
              <span v-html="displayOrderName(item)" />
            </template>
            <template #[`item.customer`]="{ item }">
              <span>{{ item.customer.firstName }} {{ item.customer.lastName }}</span>
            </template>
            <template #[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
            </template>
            <template #[`item.state.state`]="{ item }">
              <span>{{ formatState(item.state.state) }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { DateTime } from 'luxon';
  import { getPageName } from '@/services/common/Http';

  export default {
    name: 'NextOrders',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        autoRefreshTimer: undefined,
        lastRefreshTime: Date.now(),
        headers: [{
          text: this.$i18n.t('customerService.nextOrders.table.header.location'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'location.city'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.provider'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'provider'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.name'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'name'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.customer'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'customer'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.amount'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'totalPrice'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.createdAt'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'createdAt'
        }, {
          text: this.$i18n.t('customerService.nextOrders.table.header.state'),
          align: 'center',
          filterable: true,
          sortable: true,
          value: 'state.state'
        }],
        orders: [],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        search: ''
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
      this.autoRefreshTimer = setInterval(async () => {
        if (Date.now() - this.lastRefreshTime >= 30000) {
          await this.refreshData();
        } else {
          this.updateTimer();
        }
      }, 5000);
    },
    destroyed () {
      clearInterval(this.autoRefreshTimer);
    },
    methods: {
      displayOrderName (order) {
        if (typeof order.name !== 'string') {
          return '';
        }

        return '<a href="' + this.$router.resolve('/search').href + '?name=' + encodeURIComponent(order.name) + '">' + order.name.toUpperCase() + '</a>';
      },
      updateTimer () {
        const delay = Date.now() - this.lastRefreshTime;
        this.$store.commit('SET_PAGE_INFO_LINE1', {
          pageName: getPageName(),
          value: this.$i18n.t('order.timer.updated', { delay: String(parseInt(delay / 1000)) })
        });
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/next');
          if (response.data !== undefined) {
            this.orders = response.data;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
        this.lastRefreshTime = Date.now();
        this.updateTimer();
        this.loading = false;
        this.$emit('count', this.orders.length);
      },
      formatDate (date) {
        return DateTime.fromISO(date, { setZone: true }).setZone('Europe/Paris').setLocale('fr-fr').toLocaleString(DateTime.DATETIME_SHORT);
      },
      formatProvider (provider) {
        return this.$i18n.t('order.tab.details.provider.' + provider);
      },
      formatState (state) {
        return this.$i18n.t('order.tab.details.state.' + state);
      }
    }
  };
</script>
