<template>
  <v-simple-table dense>
    <template #default>
      <tbody>
        <tr v-if="order.state !== undefined && [OrderState.created, OrderState.payment, OrderState.review, OrderState.placed].includes(order.state.state) && $can('update', 'CustomerService')">
          <th colspan="2" class="text-center">
            <edit-order-detail-form :order="order" @success="success" @fail="fail" />
          </th>
        </tr>
        <tr>
          <th colspan="2" class="text-center">
            {{ $t('order.tab.details.prop.tracking') }}
          </th>
        </tr>
        <tr v-if="order.state">
          <th>{{ $t('order.tab.details.prop.state') }}</th>
          <td v-html="displayState(order.state)" />
        </tr>
        <tr v-if="order.states">
          <th>{{ $t('order.tab.details.prop.states') }}</th>
          <td>
            <div style="float:right;">
              <ol class="pa-0" style="list-style:none">
                <li v-for="(state, index) in order.states" :key="state.id" class="text-left">
                  <div v-if="index === order.states.length - 1" class="text-decoration-underline" v-html="displayState(state)" />
                  <div v-else class="font-italic" v-html="displayState(state)" />
                  <div v-if="state.note" class="font-italic text-caption">
                    {{ state.note }}
                  </div>
                </li>
              </ol>
            </div>
          </td>
        </tr>
        <tr>
          <th colspan="2" class="text-center">
            {{ $t('order.tab.details.prop.target') }}
          </th>
        </tr>
        <tr>
          <th>{{ $t('order.tab.details.prop.createdAt') }}</th>
          <td>{{ creationDate }}</td>
        </tr>
        <tr v-if="preparationDate">
          <th>
            {{ $t('order.tab.details.prop.preparation') }}
          </th>
          <td>{{ preparationDate }}</td>
        </tr>
        <tr v-if="deliveryDate">
          <th>
            {{ $t('order.tab.details.prop.delivery') }}
          </th>
          <td>{{ deliveryDate }}</td>
        </tr>
        <tr v-if="onsiteDate">
          <th>
            {{ $t('order.tab.details.prop.onsite') }}
          </th>
          <td>{{ onsiteDate }}</td>
        </tr>
        <tr v-if="pickupDate">
          <th>
            {{ $t('order.tab.details.prop.pickup') }}
          </th>
          <td>{{ pickupDate }}</td>
        </tr>
        <tr v-if="shippingDate">
          <th>
            {{ $t('order.tab.details.prop.shipping') }}
          </th>
          <td>{{ shippingDate }}</td>
        </tr>
        <tr v-if="displayResultSection">
          <th colspan="2" class="text-center">
            {{ $t('order.tab.details.prop.result') }}
          </th>
        </tr>
        <tr v-if="displayResultSection && displayTime('preparation').length > 0">
          <th>
            {{ $t('order.tab.details.prop.preparationTime') }}
          </th>
          <td><span v-html="displayTime('preparation')" /></td>
        </tr>
        <tr v-if="displayResultSection && displayTime('waiting').length > 0">
          <th>
            {{ $t('order.tab.details.prop.waitingTime') }}
          </th>
          <td><span v-html="displayTime('waiting')" /></td>
        </tr>
        <tr v-if="displayResultSection && displayTime('delivery').length > 0">
          <th>
            {{ $t('order.tab.details.prop.deliveryTime') }}
          </th>
          <td><span v-html="displayTime('delivery')" /></td>
        </tr>
        <tr v-if="displayResultSection && displayOrderDelay().length > 0">
          <th>
            {{ $t('order.tab.details.prop.orderDelay') }}
          </th>
          <td><span v-html="displayOrderDelay()" /></td>
        </tr>
        <tr>
          <th colspan="2" class="text-center">
            {{ $t('order.tab.details.prop.moreInfo') }}
          </th>
        </tr>
        <tr v-if="actionIsNotDefined">
          <th>
            {{ $t('order.tab.details.prop.delivery') }}
          </th>
          <td>{{ $t('common.unknown') }}</td>
        </tr>
        <tr v-if="Array.isArray(order.discounts) && $can('read', 'Order.Data.Detail.Discount')">
          <th>{{ $tc('order.tab.details.prop.discountCodes', order.discounts.length) }}</th>
          <td>{{ order.discounts.map((discount) => discount.code).filter((code) => code !== undefined).join(', ') }}</td>
        </tr>
        <tr v-if="Array.isArray(order.discounts) && $can('read', 'Order.Data.Detail.Discount')">
          <th>{{ $t('order.tab.details.prop.totalDiscount') }}</th>
          <td>-{{ order.totalDiscount }}</td>
        </tr>
        <!--        <tr>-->
        <!--          <th>{{ $t('order.tab.details.prop.subtotalPrice') }}</th>-->
        <!--          <td>{{ details.subtotalPrice }}</td>-->
        <!--        </tr>-->
        <!--        <tr v-if="$can('read', 'Order.Data.Detail.Price')">-->
        <!--          <th>{{ $t('order.tab.details.prop.totalTaxes') }}</th>-->
        <!--          <td>{{ order.totalTaxes }}</td>-->
        <!--        </tr>-->
        <tr v-if="$can('read', 'Order.Data.Detail.Price')">
          <th>{{ $t('order.tab.details.prop.totalPrice') }}</th>
          <td>{{ order.totalPrice }}</td>
        </tr>
        <tr v-if="order.amountToPay">
          <th>{{ $t('order.tab.details.prop.amountToPay') }}</th>
          <td>{{ order.amountToPay }}</td>
        </tr>
        <tr>
          <th>{{ $t('order.tab.details.prop.provider') }}</th>
          <td class="text-capitalize">
            {{ provider }}
          </td>
        </tr>
        <tr v-if="location">
          <th>{{ $t('order.tab.details.prop.location') }}</th>
          <td class="text-capitalize">
            <div v-if="$can('update', 'CustomerService')
              && ($can('update', 'Order.All') || (order.state !== undefined && [OrderState.created, OrderState.payment, OrderState.review, OrderState.placed].includes(order.state.state)))"
            >
              <v-select :value="location" :items="locations" item-value="key" item-text="city"
                        :disabled="locations.length <= 1 || loading" :loading="loading" dense hide-details
                        style="width:95%" @change="updateOrderLocation"
              >
                <template #selection="{ item }">
                  <span class="d-flex justify-center" style="width: 100%;">
                    {{ item.city }}
                  </span>
                </template>
                <template #item="{ item }">
                  <span>
                    {{ item.city }} {{ distance(item) }}
                  </span>
                </template>
              </v-select>
            </div>
            <div v-else>
              {{ location.name }}
            </div>
          </td>
        </tr>
        <tr>
          <th>{{ $t('order.tab.details.prop.modification') }}</th>
          <td>
            {{ lastModificationDate }}
          </td>
        </tr>
        <!--        <tr v-if="discountCodes">-->
        <!--          <th>{{ $tc('order.tab.details.prop.discountCodes', countDiscountCodes) }}</th>-->
        <!--          <td>{{ discountCodes }}</td>-->
        <!--        </tr>-->
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
  import { handleRequestError } from '@/services/common/Http';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import { displayISO, displayHourISO, secondToHHmm } from '@/util/DateUtil';
  import { DateTime } from 'luxon';
  import OrderState from '@/services/order/OrderState';
  import { getDistance } from 'geolib';
  import EditOrderDetailForm from '@/views/forms/order/EditOrderDetailForm';

  export default {
    name: 'OrderDetailPanel',
    components: { EditOrderDetailForm },
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        OrderState: OrderState,
        location: undefined,
        locations: [],
        loading: false
      };
    },
    computed: {
      actionIsNotDefined: function () {
        return (this.order.actions.delivery === undefined || this.order.actions.delivery.date === undefined)
          && (this.order.actions.onsite === undefined || this.order.actions.onsite.date === undefined)
          && (this.order.actions.pickup === undefined || this.order.actions.pickup.date === undefined)
          && (this.order.actions.shipping === undefined || this.order.actions.shipping.date === undefined);
      },
      preparationDate: function () {
        if (this.order.actions.preparation === undefined || this.order.actions.preparation.date === undefined) {
          return undefined;
        }
        const minDate = DateTime.fromISO(this.order.actions.preparation.date, { setZone: true }).minus({ minutes: 15 }).toISO();

        return [minDate, this.order.actions.preparation.date].map((date) => displayISO(date)).join(' - ');
      },
      deliveryDate: function () {
        if (this.order.actions.delivery === undefined || this.order.actions.delivery.date === undefined) {
          return undefined;
        }

        return displayISO(this.order.actions.delivery.date) + (this.order.actions.delivery.maxDate !== undefined ? ' - ' + displayISO(this.order.actions.delivery.maxDate) : '');
      },
      onsiteDate: function () {
        if (this.order.actions.onsite === undefined || this.order.actions.onsite.date === undefined) {
          return undefined;
        }

        return displayISO(this.order.actions.onsite.date);
      },
      pickupDate: function () {
        if (this.order.actions.pickup === undefined || this.order.actions.pickup.date === undefined) {
          return undefined;
        }

        return displayISO(this.order.actions.pickup.date);
      },
      shippingDate: function () {
        if (this.order.actions.shipping === undefined || this.order.actions.shipping.date === undefined) {
          return undefined;
        }

        return displayISO(this.order.actions.shipping.date);
      },
      creationDate: function () {
        return displayISO(this.order.createdAt);
      },
      provider: function () {
        return this.$i18n.t('order.tab.details.provider.' + this.order.provider);
      },
      lastModificationDate: function () {
        const dates = [];
        if (this.order.updatedAt !== undefined) {
          dates.push(this.order.updatedAt);
        }
        if (this.order.customer?.updatedAt !== undefined) {
          dates.push(this.order.customer.updatedAt);
        }
        if (this.order.state?.updatedAt !== undefined) {
          dates.push(this.order.state.updatedAt);
        }

        return displayISO(dates.reduce((max, date) => {
          if (max === undefined || max < date) {
            return date;
          }

          return max;
        }));
      },
      // ,
      // discountCodes: function () {
      //   if (this.details.discountCodes === undefined || this.details.discountCodes.length === 0) {
      //     return undefined;
      //   }
      //
      //   return this.details.discountCodes.map((discount) => discount.code).join(', ');
      // },
      // countDiscountCodes: function () {
      //   if (this.details.discountCodes === undefined || this.details.discountCodes.length === 0) {
      //     return 0;
      //   }
      //
      //   return this.details.discountCodes.length;
      // }
      displayResultSection: function () {
        return [OrderState.preparation, OrderState.ready, OrderState.delivery, OrderState.next_delivery, OrderState.arriving,
                OrderState.done, OrderState.failed, OrderState.cancelled].includes(this.order.state?.state);
      }
    },
    mounted: async function () {
      if (this.$store.state.locations !== undefined && this.$store.state.locations.length > 1) {
        this.location = this.order.location;
        this.locations = this.$store.state.locations;
      }
    },
    methods: {
      distance: function (location) {
        if (this.order.actions?.delivery?.address?.latitude === undefined || this.order.actions?.delivery?.address?.longitude === undefined) {
          return '';
        }

        const distanceInMeters = getDistance({ latitude: location.latitude, longitude: location.longitude },
                                             { latitude: this.order.actions.delivery.address.latitude, longitude: this.order.actions.delivery.address.longitude });

        return '(' + String(Math.round(distanceInMeters / 10) / 100) + 'km)';
      },
      displayState (state) {
        return displayHourISO(state.createdAt) + ' : '
          + this.$i18n.t('order.tab.details.state.' + state.state)
          + (state.user !== undefined ? ' (<a href="tel:' + phoneInt(state.user.phoneNumber) + '" title="' + displayPhone(state.user.phoneNumber) + '">' + state.user.firstName + '</a>)' : '');
      },
      displayTime: function (type) {
        let start;
        let end;
        switch (type) {
          case 'preparation':
            start = this.order.states?.find((item) => item.state === OrderState.preparation);
            end = this.order.states?.find((item) => item.state === OrderState.ready);
            break;
          case 'waiting':
            start = this.order.states?.find((item) => item.state === OrderState.ready);
            end = this.order.states?.find((item) => [OrderState.delivery, OrderState.next_delivery, OrderState.arriving, OrderState.done, OrderState.failed, OrderState.cancelled].includes(item.state));
            break;
          case 'delivery':
            start = this.order.states?.find((item) => [OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(item.state));
            end = this.order.states?.find((item) => [OrderState.done, OrderState.failed, OrderState.cancelled].includes(item.state));
            break;
        }

        if (start === undefined) {
          return '';
        }
        if (end === undefined) {
          end = { createdAt: DateTime.utc().toISO() };
        }

        const timeInSeconds = DateTime.fromISO(end.createdAt).setZone('UTC').toSeconds() - DateTime.fromISO(start.createdAt).setZone('UTC').toSeconds();
        let color = 'gray';
        switch (type) {
          case 'preparation':
            if (timeInSeconds > 16 * 60) {
              color = 'red';
            } else if (timeInSeconds > 12 * 60) {
              color = 'orange';
            }
            break;
          case 'waiting':
            if (timeInSeconds > 12 * 60) {
              color = 'red';
            } else if (timeInSeconds > 8 * 60) {
              color = 'orange';
            }
            break;
          case 'delivery':
            if (timeInSeconds > 25 * 60) {
              color = 'red';
            } else if (timeInSeconds > 20 * 60) {
              color = 'orange';
            }
            break;
        }

        return '<span class="v-chip theme--light v-size--small ' + color + '">' + secondToHHmm(timeInSeconds) + '</span>';
      },
      displayOrderDelay: function () {
        if (this.order.actions?.delivery?.date === undefined || ![OrderState.done, OrderState.failed, OrderState.cancelled].includes(this.order.state?.state)) {
          return '';
        }
        const target = DateTime.fromISO(this.order.actions.delivery.date).setZone('UTC').plus({ minutes: 30 }).toSeconds();
        const result = DateTime.fromISO(this.order.state.createdAt).setZone('UTC').toSeconds();
        const delayInSeconds = target - result;

        let color = 'gray';
        let sign = '+';
        if (delayInSeconds < -5 * 60) {
          color = 'red';
          sign = '-';
        } else if (delayInSeconds <= -1 * 60) {
          color = 'orange';
          sign = '-';
        } else if (delayInSeconds < 60 && delayInSeconds > -60) {
          sign = '';
        }

        return '<span class="v-chip theme--light v-size--small ' + color + '">' + sign + secondToHHmm(delayInSeconds) + '</span>';
      },
      async updateOrderLocation (locationKey) {
        if (confirm(this.$i18n.t('order.tab.details.confirm.updateLocation', { orderName: this.order.name }))) {
          this.loading = true;
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/location?orderId=' + String(this.order.id) + '&location=' + String(locationKey));
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.loading = false;
        }
      },
      success (orderId) {
        this.$emit('success', orderId);
      },
      async fail (error, message) {
        this.$emit('fail', await handleRequestError(error, this.$router, this.$i18n, message));
      }
    }
  };
</script>
