<template>
  <div>
    <div id="mapContainer" class="orders-map" />
    <v-row class="mt-2">
      <v-col cols="12" sm="6">
        <div class="text-h3">
          {{ $t('order.map.legend.title') }}
        </div>
        <div><span class="marker-location d-inline-block" /> {{ $t('order.map.legend.location') }}</div>
        <div><span class="marker-driver d-inline-block" /> {{ $t('order.map.legend.driver') }}</div>
        <div><span class="marker-order-ready d-inline-block" /> {{ $t('order.tab.details.state.ready') }}</div>
        <div><span class="marker-order-delivery d-inline-block" /> {{ $t('order.tab.details.state.delivery') }}</div>
        <div><span class="marker-order-done d-inline-block" /> {{ $t('order.tab.details.state.done') }}</div>
        <div><span class="marker-order-failed d-inline-block" /> {{ $t('order.tab.details.state.failed') }}</div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="text-h3">
          {{ $t('order.map.drivers.title') }}
        </div>
        <div v-for="routeId in Object.keys(routes)" :key="routeId">
          <span class="d-inline-block" :style="'width:20px;height:10px;background-color:' + routes[routeId].color + ';'" />
          {{ routes[routeId].driver.name }} : {{ $tc('order.timer.countOrders', routes[routeId].coordinates.length, { count: routes[routeId].coordinates.length }) }} (🛵 {{ formatDate(routes[routeId].date) }})
        </div>
        <div v-for="driverId in Object.keys(drivers)" :key="driverId">
          <span v-if="drivers[driverId].routeId === undefined">
            <span class="d-inline-block" :style="'width:20px;height:10px;background-color:' + drivers[driverId].color + ';'" />
            {{ drivers[driverId].name }} : {{ $tc('order.timer.countOrders', 0, { count: 0 }) }} (📍 {{ formatDate(drivers[driverId].date) }})
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import mapboxgl from 'mapbox-gl';
  import 'mapbox-gl/dist/mapbox-gl.css';
  import { displayISO } from '@/util/DateUtil';
  import OrderState from '@/services/order/OrderState';
  import { Logger } from '@/services/common/Logger';

  export default {
    name: 'OrdersMap',
    props: {
      orders: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        color: 0,
        colors: [
          '#66cc99', '#f1828d', '#ffcb05', '#a537fd',
          '#f0ff00', '#1f3a93', '#00e640', '#19b5fe',
          '#cf000f', '#be90d4', '#2e3131'
        ],
        locationMarker: undefined,
        map: undefined,
        markers: [],
        routes: {},
        drivers: {}
      };
    },
    watch: {
      orders: async function () {
        this.displayOrders();
      }
    },
    mounted: function () {
      this.initializeMap();
    },
    destroyed: function () {
      this.removeMap();
    },
    methods: {
      formatDate: displayISO,
      initializeMap () {
        mapboxgl.accessToken = 'pk.eyJ1IjoiYnVyZ2VyYnJldG9uIiwiYSI6ImNrbDdmMHdnNDFyajMycHFtMzRiZWxyeHoifQ.otGYfC69M9421HUOQ2G0bw';

        let locationTitle;
        let latitude = 48.8566969;
        let longitude = 2.3514616;
        if (this.$store.state.location !== undefined) {
          locationTitle = this.$store.state.location.name;
          if (this.$store.state.location.latitude !== undefined && this.$store.state.location.longitude !== undefined) {
            latitude = this.$store.state.location.latitude;
            longitude = this.$store.state.location.longitude;
          }
        }

        this.map = new mapboxgl.Map({
          container: 'mapContainer',
          style: 'mapbox://styles/mapbox/light-v10',
          center: [longitude, latitude],
          zoom: 12
        });

        const locationMarkerIcon = document.createElement('div');
        locationMarkerIcon.className = 'marker-location';
        this.locationMarker = new mapboxgl.Marker(locationMarkerIcon).setLngLat([longitude, latitude]).addTo(this.map);
        if (locationTitle !== undefined) {
          this.locationMarker.setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML('<h3>' + locationTitle + '</h3>'));
        }

        const nav = new mapboxgl.NavigationControl();
        this.map.addControl(nav, 'top-right');

        this.map.on('load', () => {
          this.displayOrders();
        });
      },
      removeMap () {
        if (this.map !== undefined && this.map.loaded && this.map.isStyleLoaded()) {
          if (this.locationMarker !== undefined) {
            this.locationMarker.remove();
          }
          this.removeMarkers();
          this.removeRoutes();
          this.removeDrivers();
          this.map.remove();
        }
      },
      displayOrders () {
        if (this.map !== undefined && this.map.loaded && this.map.isStyleLoaded()) {
          this.removeMarkers();
          this.displayMarkers();
          this.removeRoutes();
          this.displayRoutes();
          this.removeDrivers();
          this.displayDrivers();
        }
      },
      displayMarkers () {
        this.orders.forEach((order) => {
          if (order.state !== undefined && order.actions.delivery !== undefined
            && order.actions.delivery.address !== undefined && order.actions.delivery.address.latitude !== undefined && order.actions.delivery.address.longitude !== undefined) {
            const icon = document.createElement('div');
            switch (order.state.state) {
              case OrderState.create:
              case OrderState.payment:
              case OrderState.review:
              case OrderState.placed:
              case OrderState.preparation:
              case OrderState.ready:
                icon.className = 'marker-order-ready';
                break;
              case OrderState.delivery:
              case OrderState.next_delivery:
              case OrderState.arriving:
                icon.className = 'marker-order-delivery';
                break;
              case OrderState.done:
                icon.className = 'marker-order-done';
                break;
              case OrderState.failed:
              case OrderState.cancelled:
                icon.className = 'marker-order-failed';
                break;
            }

            const marker = new mapboxgl.Marker(icon).setLngLat([order.actions.delivery.address.longitude, order.actions.delivery.address.latitude]).addTo(this.map);
            marker.setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML('<h3>' + this.$i18n.t('order.tab.main.order') + ' ' + String(order.name) + '</h3><p>' + this.getOrderAddress(order) + '</p>'));
            this.markers.push(marker);
          }
        });
      },
      removeMarkers () {
        this.markers.forEach((marker) => {
          marker.remove();
        });
        this.markers = [];
      },
      displayRoutes () {
        this.routes = {};
        this.orders.forEach((order) => {
          let route;
          if (order.states !== undefined) {
            const deliveryState = order.states.reduce((selected, state) => {
              if (state.user === undefined) {
                return selected;
              }

              if (![OrderState.delivery, OrderState.next_delivery, OrderState.arriving, OrderState.done, OrderState.failed].includes(state.state)) {
                return selected;
              }

              return selected === undefined || selected.createdAt < state.createdAt ? state : selected;
            }, undefined);
            if (deliveryState !== undefined) {
              route = 'route-' + String(deliveryState.user.id);
              if (this.routes[route] === undefined) {
                const color = (this.colors[this.color] !== undefined ? this.colors[this.color++] : '#000000');
                this.routes[route] = {
                  color: color,
                  driver: {
                    id: deliveryState.user.id,
                    name: this.getUserName(deliveryState.user)
                  },
                  coordinates: [],
                  date: deliveryState.createdAt
                };
              } else if (this.routes[route].date < deliveryState.createdAt) {
                this.routes[route].date = deliveryState.createdAt;
              }

              if (order.actions.delivery !== undefined && order.actions.delivery.address !== undefined
                && order.actions.delivery.address.latitude !== undefined && order.actions.delivery.address.longitude !== undefined) {
                this.routes[route].coordinates.push([order.actions.delivery.address.longitude, order.actions.delivery.address.latitude]);
              }
            }
          }
        });

        Object.keys(this.routes).forEach((routeId) => {
          this.map.addSource(routeId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: this.routes[routeId].coordinates
              }
            }
          });
          this.map.addLayer({
            id: 'layer-' + routeId,
            type: 'line',
            source: routeId,
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': this.routes[routeId].color,
              'line-width': 3
            }
          });
        });
      },
      removeRoutes () {
        Object.keys(this.routes).forEach((routeId) => {
          this.map.removeLayer('layer-' + routeId);
          this.map.removeSource(routeId);
        });
        this.routes = {};
        this.color = 0;
      },
      displayDrivers () {
        this.$http.get(process.env.VUE_APP_BASE_API_URL + 'user/drivers/position').then((response) => {
          if (response.data.positions !== undefined) {
            this.removeDrivers();
            response.data.positions.forEach((position) => {
              if (position.user !== undefined) {
                const driverId = 'driver-' + String(position.user.id);
                const routeId = Object.keys(this.routes).find((id) => this.routes[id].driver.id === position.user.id);
                const color = routeId === undefined ? this.colors[this.color++] : this.routes[routeId].color;

                const driverMarkerIcon = document.createElement('div');
                driverMarkerIcon.className = 'marker-driver';
                driverMarkerIcon.style.backgroundColor = color;
                const driverMarker = new mapboxgl.Marker(driverMarkerIcon).setLngLat([position.longitude, position.latitude]).addTo(this.map);
                if (routeId !== undefined) {
                  this.map.addSource(driverId, {
                    type: 'geojson',
                    data: {
                      type: 'Feature',
                      properties: {},
                      geometry: {
                        type: 'LineString',
                        coordinates: [[position.longitude, position.latitude], this.routes[routeId].coordinates[0]]
                      }
                    }
                  });
                  this.map.addLayer({
                    id: 'layer-' + driverId,
                    type: 'line',
                    source: driverId,
                    layout: {
                      'line-join': 'round',
                      'line-cap': 'round'
                    },
                    paint: {
                      'line-color': color,
                      'line-width': 3
                    }
                  });
                  this.routes[routeId].date = position.createdAt;
                }

                this.drivers[driverId] = {
                  id: position.user.id,
                  name: this.getUserName(position.user),
                  latitude: position.latitude,
                  longitude: position.longitude,
                  marker: driverMarker,
                  routeId: routeId,
                  color: color,
                  date: position.createdAt
                };
              }
            });
            this.drivers = Object.assign({}, this.drivers);
          }
        }).catch(Logger.exception);
      },
      removeDrivers () {
        Object.keys(this.drivers).forEach((driverId) => {
          if (this.drivers[driverId].marker !== undefined) {
            this.drivers[driverId].marker.remove();
          }
          if (this.drivers[driverId].routeId !== undefined) {
            this.map.removeLayer('layer-' + driverId);
            this.map.removeSource(driverId);
          }
        });
        this.drivers = {};
      },
      getOrderAddress: function (order) {
        let action;
        if (order.actions.delivery !== undefined) {
          action = order.actions.delivery;
        } else if (order.actions.onsite !== undefined) {
          action = order.actions.onsite;
        } else if (order.actions.pickup !== undefined) {
          action = order.actions.pickup;
        } else if (order.actions.shipping !== undefined) {
          action = order.actions.shipping;
        }

        if (action !== undefined && action.address !== undefined) {
          return [action.address.address1, action.address.city].filter((part) => part !== undefined).join(', ');
        }

        return '';
      },
      getUserName: function (user) {
        if (user === undefined) {
          return '';
        }

        return user.firstName + (user.lastName === undefined ? '' : ' ' + user.lastName.charAt(0) + '.');
      }
    }
  };
</script>
