<template>
  <div>
    <v-btn text small rounded color="black" @click="selectExportDialog=true">
      <v-icon left>
        mdi-download
      </v-icon>{{ $t('common.export.button') }}
    </v-btn>
    <v-dialog v-model="selectExportDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h3 text-break">
          {{ $t('common.export.dialog.title') }}
        </v-card-title>
        <v-card-text><br></v-card-text>
        <v-card-actions>
          <v-btn color="accent" @click.stop="exportExcel()">
            {{ $t('common.export.dialog.button.excel') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="exportGoogleSheet()">
            {{ $t('common.export.dialog.button.google_sheet') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { displayDateISO } from '@/util/DateUtil';
  import { exportCsv } from '@/util/ExporterUtil';

  export default {
    name: 'TableExporter',
    props: {
      startFullDate: {
        type: String,
        required: false
      },
      endFullDate: {
        type: String,
        required: false
      },
      headers: {
        type: Array,
        required: false
      },
      items: {
        type: Array,
        required: false
      },
      data: {
        type: Array,
        required: false
      }
    },
    data () {
      return {
        selectExportDialog: false
      };
    },
    methods: {
      exportData () {
        if (this.data !== undefined) {
          return this.data;
        }

        const headers = this.headers.map((header) => header.text);
        const data = [[]];

        if (this.startFullDate !== undefined && this.endFullDate !== undefined) {
          const period = this.$i18n.t('common.time.from') + ' ' + displayDateISO(this.startFullDate) + ' ' + this.$i18n.t('common.time.to') + ' ' + displayDateISO(this.endFullDate);
          data.push([period]);
          data.push([]);
        }
        data.push(headers);
        this.items.forEach((product) => {
          const item = Object.values(product);
          data.push(item);
        });

        return data;
      },
      exportExcel () {
        this.selectExportDialog = false;
        exportCsv(this.exportData(), 'excel');
      },
      exportGoogleSheet () {
        this.selectExportDialog = false;
        exportCsv(this.exportData(), 'google_sheet');
      }
    }
  };
</script>
