export const exportCsv = (data, target = 'excel') => {
  const delimiter = (target === 'excel' ? ';' : ',');
  let csvContent = 'data:text/csv;charset=utf-8,\uFEFF';
  csvContent += [
    ...data.map((columns) => '"' + columns.join('"' + delimiter + '"') + '"')
  ].join('\n').replace(/(^\[)|(\]$)/gm, '');

  const content = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', content);
  link.setAttribute('download', target + '.csv');
  link.click();
};
