<template>
  <v-container id="recall-customers" fluid tag="section">
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.recallCustomers.subtitle') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="calls" :loading="loading" :hide-default-footer="true">
            <template #[`item.phone`]="{ item }">
              <span v-html="formatPhone(item.phone)" />
            </template>
            <template #[`item.state`]="{ item }">
              {{ $t('customerService.recallCustomers.state.' + item.state) }}
            </template>
            <template #[`item.startedAt`]="{ item }">
              {{ formatDate(item.startedAt) }}
            </template>
            <template #[`item.action`]="{ item }">
              <span v-html="formatAction(item.phone)" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';

  export default {
    name: 'RecallCustomers',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        calls: [],
        loading: false,
        headers: [
          {
            text: this.$i18n.t('customerService.recallCustomers.column.phone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'phone'
          },
          {
            text: this.$i18n.t('customerService.recallCustomers.column.state'),
            align: 'center',
            filterable: false,
            sortable: true,
            value: 'state'
          },
          {
            text: this.$i18n.t('customerService.recallCustomers.column.startedAt'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'startedAt'
          },
          {
            text: this.$i18n.t('customerService.recallCustomers.column.action'),
            align: 'right',
            filterable: false,
            sortable: false,
            value: 'action'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['startedAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatPhone (phone) {
        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      formatAction (phone) {
        return '<a href="' + this.$router.resolve('/search').href + '?phone=' + encodeURIComponent(phoneInt(phone)) + '">'
          + this.$i18n.t('customerService.recallCustomers.button.search') + ' ' + displayPhone(phone) + '</a>';
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'customer/call/incoming/missed');
          if (response.data !== undefined) {
            this.calls = response.data.calls;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.$emit('count', this.calls.length);
      }
    }
  };
</script>
