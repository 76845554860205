<template>
  <div>
    <v-btn text small rounded color="black" @click="print()">
      <v-icon left>
        mdi-printer
      </v-icon>{{ $t('common.print.button') }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'TablePrinter',
    props: {
      elementId: {
        type: String,
        required: true
      }
    },
    methods: {
      print () {
        window.print();
      }
    }
  };
</script>

<style>
@media print {
  header, nav, footer {
    display: none !important;
  }

  main {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
