<template>
  <div>
    <edit-order-customer-form v-model="editOrderCustomer" :order="order" @success="success" @fail="fail" />
    <edit-order-driver-form v-if="driver" v-model="editOrderDriver" :order="order" @success="success" @fail="fail" />
    <v-simple-table dense>
      <template #default>
        <tbody>
          <tr>
            <th colspan="2" class="text-center">
              <v-btn text small rounded color="grey" :loading="editOrderCustomer" @click.stop="editOrderCustomer=true">
                <v-icon left>
                  mdi-pencil
                </v-icon>{{ $t('order.button.editOrderCustomer') }}
              </v-btn>
            </th>
          </tr>
          <tr v-if="order.customer.ordersCount">
            <th>{{ $t('order.tab.customer.prop.ordersCount') }}</th>
            <td>{{ order.customer.ordersCount }}</td>
          </tr>
          <tr v-if="order.customer.totalSpent">
            <th>{{ $t('order.tab.customer.prop.totalSpent') }}</th>
            <td>{{ order.customer.totalSpent }}</td>
          </tr>
          <tr>
            <th>{{ $t('order.tab.customer.prop.name') }}</th>
            <td>{{ order.customer.firstName }} {{ order.customer.lastName }}</td>
          </tr>
          <tr v-if="order.customer.company">
            <th>{{ $t('order.tab.customer.prop.company') }}</th>
            <td>{{ order.customer.company }}</td>
          </tr>
          <tr v-if="order.customer.phone">
            <th>{{ $t('order.tab.customer.prop.phone') }}</th>
            <td style="white-space: pre-line">
              <a :href="'tel:' + phoneInt(order.customer.phone)" target="_blank">{{ displayPhone(order.customer.phone) }}</a>
            </td>
          </tr>
          <tr v-if="order.customer.phone2">
            <th>{{ $t('order.tab.customer.prop.phone2') }}</th>
            <td style="white-space: pre-line">
              <a :href="'tel:' + phoneInt(order.customer.phone2)" target="_blank">{{ displayPhone(order.customer.phone2) }}</a>
            </td>
          </tr>
          <tr v-if="order.customer.phone === undefined">
            <td colspan="2" class="text-center">
              ⚠️Téléphone à renseigner
            </td>
          </tr>
          <tr v-if="order.customer.email && $can('read', 'CustomerService')">
            <th>{{ $t('order.tab.customer.prop.email') }}</th>
            <td class="text-break">
              <a :href="'mailto:' + order.customer.email" target="_blank">{{ order.customer.email }}</a>
            </td>
          </tr>
          <tr v-if="address && [OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state)">
            <th>{{ $t('order.tab.customer.prop.gps') }}</th>
            <td class="text">
              <v-btn v-if="gpsGoogleMaps" color="secondary" class="white--text" small :href="gpsGoogleMaps" target="_blank">
                Google
                <v-icon right dark>
                  mdi-google-maps
                </v-icon>
              </v-btn>
              <v-btn v-if="gpsWaze" color="secondary" class="ma-2 white--text" small :href="gpsWaze" target="_blank">
                Waze
                <v-icon right dark>
                  mdi-waze
                </v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="address">
            <th>{{ $t('order.tab.customer.prop.address') }}</th>
            <td class="text">
              <span v-if="linkGoogleMaps.length > 0"><a :href="linkGoogleMaps" target="_blank">{{ address }}</a></span>
              <span v-else>{{ address }}</span>
            </td>
          </tr>
          <tr v-if="$can('read', 'CustomerService') && addressObj() !== undefined
            && (addressObj().address1 === undefined || addressObj().address1.length < 7 || addressObj().city === undefined || addressObj().zip === undefined)"
          >
            <td colspan="2" class="text-center">
              ⚠️Adresse incomplète
            </td>
          </tr>
          <tr v-else-if="$can('read', 'CustomerService') && addressObj() !== undefined && !addressObj().address1.match(/\d+/)">
            <td colspan="2" class="text-center">
              ⚠️Numéro de rue à renseigner
            </td>
          </tr>
          <tr v-else-if="$can('read', 'CustomerService') && addressObj() !== undefined && (addressObj().latitude === undefined || addressObj().longitude === undefined)">
            <td colspan="2" class="text-center">
              ⚠️Adresse introuvable
            </td>
          </tr>
          <tr v-if="deliveryBy">
            <th>{{ $t('order.tab.customer.prop.deliveryBy') }}</th>
            <td>{{ deliveryBy }}</td>
          </tr>
          <tr v-if="driver">
            <th>{{ $t('order.tab.customer.prop.driver') }}</th>
            <td>
              <span v-html="driver" />
              <v-btn v-if="$can('update', 'CustomerService') && ![OrderState.done, OrderState.cancelled].includes(order.state.state)" text x-small rounded color="grey"
                     :loading="editOrderDriver" @click.stop="editOrderDriver=true"
              >
                <v-icon left class="ma-0">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </td>
          </tr>
          <tr v-if="note">
            <th>{{ $t('order.tab.customer.prop.note') }}</th>
            <td>{{ note }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div v-if="($can('update', 'Order.Delivery.My') || $can('update', 'Order.Pickup')) && order.actions.delivery && [OrderState.ready, OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state)"
         class="my-2 text-center"
    >
      <v-btn v-if="($can('update', 'Order.All') || ($can('update', 'Order.Pickup') && order.actions.delivery !== undefined && order.actions.delivery.provider !== 'burgerbreton')) && order.state.state === OrderState.ready" :disabled="loading" :loading="loading" color="secondary" @click.stop="orderInDelivery">
        {{ $t('order.button.delivery') }}
      </v-btn>
      <div v-else-if="[OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(order.state.state)">
        <p>
          <v-btn :disabled="loading" :loading="loading" color="accent" @click.stop="orderIsDoneDialog=true">
            {{ $t('order.button.delivered') }}
          </v-btn>
        </p>
        <v-btn :disabled="loading" :loading="loading" color="accent" small text class="text-none" @click.stop="orderHasFailedDialog=true">
          {{ $t('order.button.undelivered') }}
        </v-btn>
      </div>
    </div>
    <div v-else-if="$can('update', 'Order.Pickup') && (order.actions.pickup || order.actions.onsite) && [OrderState.ready].includes(order.state.state)" class="my-2 text-center">
      <v-btn :disabled="loading" :loading="loading" color="accent" @click.stop="orderIsDone">
        {{ $t('order.button.picked_up') }}
      </v-btn>
    </div>
    <div v-else-if="$can('update', 'CustomerService') && [OrderState.review, OrderState.failed].includes(order.state.state)" class="my-2 text-center">
      <v-btn v-if="order.state.state === OrderState.review" :disabled="loading" :loading="loading" color="secondary" @click.stop="orderIsPlaced">
        {{ $t('order.button.placed') }}
      </v-btn>
      <v-btn v-else-if="order.state.state === OrderState.failed" :disabled="loading" :loading="loading" color="accent" @click.stop="orderIsDoneDialog=true">
        {{ $t('order.button.delivered') }}
      </v-btn>
    </div>
    <v-dialog v-model="orderIsDoneDialog" persistent max-width="500" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="orderIsDoneDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h3 text-break">
          {{ $t('order.confirmation.delivered.title', {orderName: order.name.toUpperCase()}) }}
        </v-card-title>
        <v-card-text><br></v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="orderIsDoneDialog=false, orderHasFailedDialog=true">
            {{ $t('order.confirmation.delivered.no') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="orderIsDoneDialog=false, orderIsDone()">
            {{ $t('order.confirmation.delivered.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="orderHasFailedDialog" max-width="600" :fullscreen="true">
      <v-card>
        <v-toolbar dense color="primary">
          <v-btn icon dark @click.stop="orderHasFailedDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h3 text-break">
          {{ $t('order.confirmation.undelivered.title', {orderName: order.name.toUpperCase()}) }}
        </v-card-title>
        <v-card-text>
          <ol>
            <li v-for="content in $t('order.confirmation.undelivered.description')" :key="content">
              {{ content }}
            </li>
          </ol>
          <br>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click.stop="orderHasFailedDialog=false, orderIsDoneDialog=true">
            {{ $t('order.confirmation.undelivered.delivered') }}
          </v-btn>
          <v-spacer />
          <v-btn color="accent" @click.stop="orderHasFailedDialog=false, orderHasFailed()">
            {{ $t('order.confirmation.undelivered.undelivered') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import { handleRequestError } from '@/services/common/Http';
  import OrderState from '@/services/order/OrderState';
  import EditOrderCustomerForm from '@/views/forms/order/EditOrderCustomerForm';
  import EditOrderDriverForm from '@/views/forms/order/EditOrderDriverForm';

  export default {
    name: 'OrderCustomerPanel',
    components: { EditOrderCustomerForm, EditOrderDriverForm },
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        OrderState: OrderState,
        orderIsDoneDialog: false,
        orderHasFailedDialog: false,
        editOrderCustomer: false,
        editOrderDriver: false,
        loading: false
      };
    },
    computed: {
      address: function () {
        const address = this.addressObj();

        let street = '';
        let zipcode = '';
        let city = '';
        if (address !== undefined) {
          street = (address.address1 !== undefined ? address.address1 : '') + (address.address2 !== undefined ? '\n' + address.address2 : '');
          if (address.zip !== undefined) {
            zipcode = address.zip;
          }
          if (address.city !== undefined) {
            city = address.city;
          }
        }

        return street + (zipcode.length > 0 || city.length > 0 ? '\n' : '') + zipcode + (zipcode.length > 0 && city.length > 0 ? ' - ' : '') + city;
      },
      deliveryBy: function () {
        let deliveryBy;
        if (this.order.actions.delivery !== undefined && ![undefined, 'shopify', 'unknown', 'burgerbreton'].includes(this.order.actions.delivery.provider)) {
          deliveryBy = this.$i18n.t('order.tab.details.provider.' + this.order.actions.delivery.provider);
        }

        return deliveryBy;
      },
      driver: function () {
        let driver;
        if (this.order.states !== undefined && this.order.states.length > 0) {
          const deliveryState = this.order.states.reduce((deliveryState, current) => {
            if ([OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(current.state)) {
              return current;
            }

            return deliveryState;
          }, undefined);
          if (deliveryState !== undefined && deliveryState.user !== undefined) {
            driver = (deliveryState.user.firstName === undefined ? '' : deliveryState.user.firstName)
              + (deliveryState.user.lastName === undefined ? '' : ' ' + deliveryState.user.lastName.charAt(0) + '.');
            if (deliveryState.user.phoneNumber !== undefined) {
              driver = '<a href="tel:' + this.phoneInt(deliveryState.user.phoneNumber) + '" title="' + this.displayPhone(deliveryState.user.phoneNumber) + '">' + driver + '</a>';
            }
          }
        }

        return driver;
      },
      linkGoogleMaps: function () {
        const address = this.addressObj();
        if (address !== undefined) {
          return 'https://maps.google.com/?q=' + encodeURIComponent([address.address1, address.city, address.zip, address.country].filter((part) => part !== undefined).join(', '));
        }

        return '';
      },
      gpsGoogleMaps: function () {
        const address = this.addressObj();
        if (address !== undefined) {
          return 'https://maps.google.com/?daddr=' + encodeURIComponent([address.address1, address.city, address.zip, address.country].filter((part) => part !== undefined).join(', '));
        }

        return '';
      },
      gpsWaze: function () {
        const address = this.addressObj();
        // doc https://developers.google.com/waze/deeplinks
        if (address !== undefined) {
          return 'https://waze.com/ul?q=' + encodeURIComponent([address.address1, address.city, address.zip, address.country].filter((part) => part !== undefined).join(', '));
        } else if (address !== undefined && address.latitude !== undefined && address.longitude !== undefined) {
          return 'https://waze.com/ul?ll=' + address.latitude + ',' + address.longitude + '&navigate=yes&zoom=17';
        }

        return '';
      },
      note: function () {
        let note;
        if (this.order.actions.delivery !== undefined) {
          note = this.order.actions.delivery.note;
        } else if (this.order.actions.onsite !== undefined) {
          note = this.order.actions.onsite.note;
        } else if (this.order.actions.pickup !== undefined) {
          note = this.order.actions.pickup.note;
        } else if (this.order.actions.shipping !== undefined) {
          note = this.order.actions.shipping.note;
        }

        return note;
      }
    },
    methods: {
      displayPhone: displayPhone,
      phoneInt: phoneInt,
      addressObj: function () {
        let action;
        if (this.order.actions.delivery !== undefined) {
          action = this.order.actions.delivery;
        } else if (this.order.actions.onsite !== undefined) {
          action = this.order.actions.onsite;
        } else if (this.order.actions.pickup !== undefined) {
          action = this.order.actions.pickup;
        } else if (this.order.actions.shipping !== undefined) {
          action = this.order.actions.shipping;
        }

        return (action?.address === undefined ? undefined : action.address);
      },
      async orderIsPlaced () {
        if (!this.loading) {
          let actionType = '';
          if (this.order.actions.delivery !== undefined) {
            actionType = 'en livraison';
          } else if (this.order.actions.onsite !== undefined) {
            actionType = 'sur place';
          } else if (this.order.actions.pickup !== undefined) {
            actionType = 'à emporter';
          }

          if (confirm(this.$i18n.t('order.confirmation.placed.title', { actionType: actionType, locationName: this.order.location?.name }))) {
            this.setLoading(true);
            try {
              await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/placed', { orderId: this.order.id });
              this.$emit('success', this.order.id);
            } catch (error) {
              this.$emit('fail', error, this.$i18n.t('order.error'));
            }
            this.setLoading(false);
          }
        }
      },
      async orderInDelivery () {
        if (!this.loading) {
          this.setLoading(true);
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/delivery', { orderId: this.order.id });
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.setLoading(false);
        }
      },
      async orderIsDone () {
        if (!this.loading) {
          this.setLoading(true);
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/done', { orderId: this.order.id });
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.setLoading(false);
        }
      },
      async orderHasFailed () {
        if (!this.loading) {
          this.setLoading(true);
          try {
            await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/failed', { orderId: this.order.id });
            this.$emit('success', this.order.id);
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
          this.setLoading(false);
        }
      },
      success (orderId) {
        this.$emit('success', orderId);
      },
      async fail (error, message) {
        this.$emit('fail', await handleRequestError(error, this.$router, this.$i18n, message));
      },
      setLoading: function (isLoading) {
        if (isLoading) {
          this.loading = true;
        } else {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
      }
    }
  };
</script>
