<template>
  <v-container id="customer-service-orders" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="refreshTabs">
        <v-tab>{{ $t('customerService.productionEvents.title') }} <span>{{ displayCounter(countProductionEvents) }}</span></v-tab>
        <v-tab>{{ $t('customerService.ordersInReview.title') }} <span>{{ displayCounter(countOrdersInReview) }}</span></v-tab>
        <v-tab>{{ $t('customerService.ordersInPayment.title') }} <span>{{ displayCounter(countOrdersInPayment) }}</span></v-tab>
        <v-tab>{{ $t('customerService.recallCustomers.title') }} <span>{{ displayCounter(countRecallCustomers) }}</span></v-tab>
        <v-tab>{{ $t('customerService.partialOrders.title') }} <span>{{ displayCounter(countPartialOrders) }}</span></v-tab>
        <v-tab>{{ $t('customerService.failedOrders.title') }} <span>{{ displayCounter(countFailedOrders) }}</span></v-tab>
        <v-tab>{{ $t('customerService.lateOrders.title') }} <span>{{ displayCounter(countLateOrders) }}</span></v-tab>
        <v-tab>{{ $t('customerService.ordersAbandoned.title') }} <span>{{ displayCounter(countOrdersAbandoned) }}</span></v-tab>
        <v-tab>{{ $t('customerService.recurrentCustomers.title') }} <span>{{ displayCounter(countRecurrentCustomers) }}</span></v-tab>
        <v-tab>{{ $t('customerService.nextOrders.title') }} <span>{{ displayCounter(countNextOrders) }}</span></v-tab>
        <v-tab>{{ $t('customerService.support.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshTabs">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="1" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <production-events :refresh="refreshProductionEvents" @success="success" @fail="fail" @count="counterProductionEvents" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="2" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <orders-in-review :refresh="refreshOrdersInReview" @success="success" @fail="fail" @count="counterOrdersInReview" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <orders-in-payment :refresh="refreshOrdersInPayment" @success="success" @fail="fail" @count="counterOrdersInPayment" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="4" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <recall-customers :refresh="refreshRecallCustomers" @success="success" @fail="fail" @count="counterRecallCustomers" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="5" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <partial-orders :refresh="refreshPartialOrders" @success="success" @fail="fail" @count="counterPartialOrders" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="6" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <failed-orders :refresh="refreshFailedOrders" @success="success" @fail="fail" @count="counterFailedOrders" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="7" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <late-orders :refresh="refreshLateOrders" @success="success" @fail="fail" @count="counterLateOrders" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="8" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <orders-abandoned :refresh="refreshOrdersAbandoned" @success="success" @fail="fail" @count="counterOrdersAbandoned" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="9" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <recurrent-customers :refresh="refreshRecurrentCustomers" @success="success" @fail="fail" @count="counterRecurentCustomers" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="10" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <next-orders :refresh="refreshNextOrders" @success="success" @fail="fail" @count="counterNextOrders" />
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="$can('read', 'Report.Meta')" key="11" :eager="true">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <customer-support @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import ProductionEvents from '@/views/components/business/customerService/ProductionEvents';
  import OrdersInReview from '@/views/components/business/customerService/OrdersInReview';
  import RecallCustomers from '@/views/components/business/customerService/RecallCustomers';
  import FailedOrders from '@/views/components/business/customerService/FailedOrders';
  import PartialOrders from '@/views/components/business/customerService/PartialOrders';
  import LateOrders from '@/views/components/business/customerService/LateOrders';
  import RecurrentCustomers from '@/views/components/business/customerService/RecurrentCustomers';
  import OrdersInPayment from '@/views/components/business/customerService/OrdersInPayment';
  import OrdersAbandoned from '@/views/components/business/customerService/OrdersAbandoned';
  import NextOrders from '@/views/components/business/customerService/NextOrders';
  import CustomerSupport from '@/views/components/business/customerService/CustomerSupport';

  export default {
    name: 'Orders',
    components: { AlertNotification, ProductionEvents, OrdersInReview, PartialOrders, FailedOrders, LateOrders, NextOrders, OrdersInPayment, OrdersAbandoned, RecallCustomers, RecurrentCustomers, CustomerSupport },
    data () {
      return {
        alert: '',
        autoRefreshTimer: undefined,
        countProductionEvents: 0,
        countOrdersInReview: 0,
        countOrdersInPayment: 0,
        countRecallCustomers: 0,
        countPartialOrders: 0,
        countFailedOrders: 0,
        countLateOrders: 0,
        countOrdersAbandoned: 0,
        countRecurrentCustomers: 0,
        countNextOrders: 0,
        loading: false,
        refreshProductionEvents: Date.now(),
        refreshOrdersInReview: Date.now(),
        refreshOrdersInPayment: Date.now(),
        refreshRecallCustomers: Date.now(),
        refreshPartialOrders: Date.now(),
        refreshFailedOrders: Date.now(),
        refreshLateOrders: Date.now(),
        refreshOrdersAbandoned: Date.now(),
        refreshRecurrentCustomers: Date.now(),
        refreshNextOrders: Date.now()
      };
    },
    mounted () {
      this.refresh();
      this.autoRefreshTimer = setInterval(() => this.refresh(), 5000);
    },
    destroyed () {
      clearInterval(this.autoRefreshTimer);
    },
    methods: {
      displayCounter (counter) {
        if (counter === 0) {
          return '';
        }

        if (counter >= 10) {
          return '(10+)';
        }

        return '(' + String(counter) + ')';
      },
      refresh () {
        if (this.getDelaySinceLastUpdate() >= 30000) {
          this.refreshTabs();
        } else {
          this.updateTimer();
        }
      },
      refreshTabs () {
        this.refreshProductionEvents = Date.now();
        this.refreshOrdersInReview = Date.now();
        this.refreshOrdersInPayment = Date.now();
        this.refreshRecallCustomers = Date.now();
        this.refreshPartialOrders = Date.now();
        this.refreshFailedOrders = Date.now();
        this.refreshLateOrders = Date.now();
        this.refreshOrdersAbandoned = Date.now();
        this.refreshRecurrentCustomers = Date.now();
        this.refreshNextOrders = Date.now();
        this.updateTimer();
      },
      getDelaySinceLastUpdate () {
        return Date.now() - Math.max(this.refreshProductionEvents, this.refreshOrdersInReview, this.refreshOrdersInPayment, this.refreshRecallCustomers, this.refreshPartialOrders,
                                     this.refreshFailedOrders, this.refreshLateOrders, this.refreshOrdersAbandoned, this.refreshRecurrentCustomers, this.refreshNextOrders);
      },
      updateTimer () {
        const delay = this.getDelaySinceLastUpdate();
        this.$store.commit('SET_PAGE_INFO_LINE1', {
          pageName: getPageName(),
          value: this.$i18n.t('order.timer.updated', { delay: String(parseInt(delay / 1000)) })
        });
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      },
      counterProductionEvents (count) {
        this.countProductionEvents = count;
      },
      counterOrdersInReview (count) {
        this.countOrdersInReview = count;
      },
      counterOrdersInPayment (count) {
        this.countOrdersInPayment = count;
      },
      counterRecallCustomers (count) {
        this.countRecallCustomers = count;
      },
      counterPartialOrders (count) {
        this.countPartialOrders = count;
      },
      counterFailedOrders (count) {
        this.countFailedOrders = count;
      },
      counterLateOrders (count) {
        this.countLateOrders = count;
      },
      counterOrdersAbandoned (count) {
        this.countOrdersAbandoned = count;
      },
      counterRecurentCustomers (count) {
        this.countRecurrentCustomers = count;
      },
      counterNextOrders (count) {
        this.countNextOrders = count;
      }
    }
  };
</script>
