<template>
  <div :id="'order-card-' + order.id">
    <cancel-order-form v-model="cancelOrder" :order="order" @success="success" @fail="fail" />
    <base-material-card :color="cardHeaderColor" :background-color="cardBackgroundColor" class="px-2 pt-1 pb-4 order-card">
      <template #heading>
        <v-container class="pa-0" fluid>
          <v-row no-gutters align="center" justify="center">
            <v-col v-if="providerLogo" cols="2" class="text-center">
              <img :src="providerLogo" width="40px" style="vertical-align:middle;">
            </v-col>
            <v-col :sm="providerLogo ? 10 : 12" :lg="providerLogo ? 8 : 10" :xl="providerLogo ? 8 : 10" :class="providerLogo ? 'pl-1' : ''">
              <div class="display-2 font-weight-light text-uppercase">
                {{ actionType }} {{ order.name }} <order-countdown v-if="timerDate" :target-date="timerDate" :refresh="refresh" @updated-timer-type="changeTimerType" />
              </div>
              <div v-if="$can('read', 'Order.Data.Customer') && orderAddress" class="subtitle-1 font-weight-light">
                {{ orderAddress }}
              </div>
            </v-col>
            <v-col cols="2" class="text-center text-uppercase display-2 d-none d-lg-block d-xl-block">
              <order-timer :order="order" :refresh="refresh" />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-card-text>
        <v-expansion-panels v-model="panel" :accordion="true" :multiple="true" :focusable="true" :flat="true" :hover="true" :tile="true">
          <v-expansion-panel :disabled="!$can('read', 'Order.Data.Product')">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $tc('order.tab.product.title', countProducts, {name: order.name, count: countProducts}) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <order-product-panel :order="order" @success="success" @fail="fail" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!$can('read', 'Order.Data.Customer')">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('order.tab.customer.title', {name: order.name}) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <order-customer-panel :order="order" @success="success" @fail="fail" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :disabled="!$can('read', 'Order.Data.Detail')">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ actionType }} [{{ order.name }}]
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <order-detail-panel :order="order" @success="success" @fail="fail" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <template #actions>
        <v-container class="mt-6 pa-0">
          <v-row v-if="order.state.state === OrderState.payment" align="center" justify="center" class="mb-3">
            <v-col cols="auto" class="ma-0 pa-1">
              <v-btn medium dark color="primary" :href="getPaymentLink()" target="_blank">
                <v-icon class="mr-2">
                  mdi-credit-card-outline
                </v-icon>
                {{ $t('order.button.pay') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col v-if="($can('update', 'CustomerService') && [OrderState.created, OrderState.review, OrderState.placed].includes(order.state.state))
              || ($can('update', 'CustomerService.Refund') && ![OrderState.cancelled].includes(order.state.state))" cols="auto" class="ma-0 pa-1"
            >
              <v-btn small rounded color="senary" :loading="cancelOrder" :disabled="['deliveroo', 'justeat'].includes(order.provider)" @click.stop="cancelOrder=true">
                <v-icon left>
                  mdi-cancel
                </v-icon>{{ $t('order.button.cancel') }}
              </v-btn>
            </v-col>
            <v-col v-if="$can('update', 'CustomerService.Claim') && [OrderState.cancelled, OrderState.done].includes(order.state.state)" cols="auto" class="ma-0 pa-1">
              <claim-order-form v-model="cancelOrder" :order="order" @success="success" @fail="fail" />
            </v-col>
            <v-col v-if="$can('update', 'CustomerService.Refund') && ![OrderState.cancelled].includes(order.state.state)" cols="auto" class="ma-0 pa-1">
              <v-btn small rounded color="senary" :loading="cancelOrder" :disabled="['deliveroo', 'justeat'].includes(order.provider)" @click.stop="cancelOrder=true">
                <v-icon left>
                  mdi-credit-card-refund
                </v-icon>{{ $t('order.button.refund') }}
              </v-btn>
            </v-col>
            <v-col v-if="$can('update', 'CustomerService')" cols="auto" class="ma-0 pa-1">
              <v-btn small rounded color="senary" :loading="downloadOrder" @click="download">
                <v-icon left>
                  mdi-download
                </v-icon>{{ $t('order.button.download') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </base-material-card>
  </div>
</template>
<script>
  import { getPageName, handleRequestError } from '@/services/common/Http';
  import OrderState from '@/services/order/OrderState';
  import OrderCountdown from '@/views/components/business/order/OrderCountdown';
  import OrderTimer from '@/views/components/business/order/OrderTimer';
  import OrderDetailPanel from '@/views/components/business/order/OrderDetailPanel';
  import OrderCustomerPanel from '@/views/components/business/order/OrderCustomerPanel';
  import OrderProductPanel from '@/views/components/business/order/OrderProductPanel';
  import ClaimOrderForm from '@/views/forms/order/ClaimOrderForm';
  import CancelOrderForm from '@/views/forms/order/CancelOrderForm';
  import html2canvas from 'html2canvas';

  export default {
    name: 'OrderCard',
    components: { ClaimOrderForm, CancelOrderForm, OrderCountdown, OrderTimer, OrderProductPanel, OrderCustomerPanel, OrderDetailPanel },
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        OrderState: OrderState,
        refresh: 0,
        timerInterval: null,
        cancelOrder: false,
        downloadOrder: false,
        panel: [],
        cardHeaderColor: 'info',
        cardBackgroundColor: 'white',
        previousOrderState: undefined,
        providerLogo: ''
      };
    },
    computed: {
      actionType: function () {
        let action = this.$t('order.tab.details.title');
        if (this.order.actions.delivery !== undefined) {
          action = this.$t('order.tab.details.prop.delivery');
        } else if (this.order.actions.onsite !== undefined) {
          action = this.$t('order.tab.details.prop.onsite');
        } else if (this.order.actions.pickup !== undefined) {
          action = this.$t('order.tab.details.prop.pickup');
        } else if (this.order.actions.shipping !== undefined) {
          action = this.$t('order.tab.details.prop.shipping');
        }

        return action;
      },
      countProducts: function () {
        const products = Array.isArray(this.order.preparations) ? this.order.preparations : [];
        let counter = 0;
        products.forEach((product) => {
          counter += Math.ceil(product.quantity);
          if (product.options !== undefined) {
            product.options.forEach((option) => {
              counter += Math.ceil(option.quantity);
            });
          }
        });

        return counter;
      },
      orderAddress: function () {
        const address = this.order.actions?.delivery?.address;
        if (address !== undefined) {
          let distance = '';
          if (address.distance !== undefined && this.$can('read', 'Order.Delivery.All')) {
            const distanceInKm = address.distance / 1000;
            distance = ' (' + (distanceInKm <= 10 ? distanceInKm.toFixed(2) : distanceInKm.toFixed(0)) + 'km)';
          }
          return [address.address1, address.city].filter((part) => part !== undefined).join(', ') + distance;
        }

        return '';
      },
      timerDate: function () {
        switch (this.order.state.state) {
          case OrderState.created:
          case OrderState.payment:
          case OrderState.review:
          case OrderState.placed:
          case OrderState.preparation:
            if (this.order.actions.preparation !== undefined) {
              return this.order.actions.preparation.date;
            }
            break;
          case OrderState.ready:
          case OrderState.delivery:
          case OrderState.next_delivery:
          case OrderState.arriving:
            if (this.order.actions.delivery !== undefined) {
              if (this.order.actions.delivery.maxDate !== undefined) {
                return this.order.actions.delivery.maxDate;
              }
              return this.order.actions.delivery.date;
            } else if (this.order.actions.onsite !== undefined) {
              return this.order.actions.onsite.date;
            } else if (this.order.actions.pickup !== undefined) {
              return this.order.actions.pickup.date;
            } else if (this.order.actions.shipping !== undefined) {
              return this.order.actions.shipping.date;
            }
            break;
          case OrderState.done:
          case OrderState.failed:
          case OrderState.cancelled:
            return undefined;
        }

        return undefined;
      }
    },
    watch: {
      order: function () {
        this.updateBackground();
        this.updatePanels();
        this.updateTimer();
      }
    },
    mounted () {
      this.updateBackground();

      if (this.order.actions !== undefined && this.order.provider !== undefined && !['shopify', 'burgerbreton', 'unknown', undefined].includes(this.order.provider)) {
        this.providerLogo = require('@/assets/provider/' + this.order.provider + '.png');
      }

      this.updatePanels();
      this.startTimer();
    },
    beforeDestroy () {
      this.stopTimer();
    },
    methods: {
      startTimer () {
        this.stopTimer();
        this.updateTimer();
        this.timerInterval = setInterval(() => this.updateTimer(), 30000);
      },
      updateTimer () {
        this.refresh = Date.now();
      },
      stopTimer () {
        clearInterval(this.timerInterval);
      },
      changeTimerType: function (value) {
        this.cardHeaderColor = value;
      },
      updateBackground: function () {
        if (this.order.actions.delivery !== undefined) {
          this.cardBackgroundColor = 'delivery';
        } else if (this.order.actions.onsite !== undefined) {
          this.cardBackgroundColor = 'onsite';
        } else if (this.order.actions.pickup !== undefined) {
          this.cardBackgroundColor = 'pickup';
        } else if (this.order.actions.shipping !== undefined) {
          this.cardBackgroundColor = 'shipping';
        }
      },
      updatePanels: function () {
        if (this.order.state.state !== this.previousOrderState) {
          switch (getPageName()) {
            case 'customer-service/orders':
              this.panel = [1, 2];
              break;
            case 'search':
              this.panel = [0, 1, 2];
              break;
            default:
              switch (this.order.state.state) {
                case OrderState.payment:
                case OrderState.review:
                case OrderState.ready:
                case OrderState.delivery:
                case OrderState.next_delivery:
                case OrderState.arriving:
                  this.panel = [1];
                  break;
                case OrderState.created:
                case OrderState.placed:
                case OrderState.preparation:
                  this.panel = [0];
                  break;
                default:
                  if (this.$isMobile()) {
                    this.panel = [];
                  } else {
                    this.panel = [0, 1, 2];
                  }
              }
          }

          this.previousOrderState = this.order.state.state;
        }
      },
      success (orderId) {
        this.$emit('refresh', orderId);
      },
      async fail (error, message) {
        this.$emit('error', await handleRequestError(error, this.$router, this.$i18n, message));
      },
      download () {
        const previousPanel = this.panel;
        this.downloadOrder = true;
        this.panel = [0, 1, 2];
        setTimeout(async () => {
          try {
            const canvas = await html2canvas(document.querySelector('#order-card-' + this.order.id), {
              scale: 4,
              backgroundColor: null,
              logging: false
            });

            const a = document.createElement('a');
            a.href = canvas.toDataURL('image/png');
            a.download = this.$i18n.t('order.confirmation.download.filename') + '_' + this.order.name + '.png';
            a.click();
          } catch (error) {
            this.$emit('error', error.message);
          }
          this.panel = previousPanel;
          this.downloadOrder = false;
        }, 1);
      },
      getPaymentLink () {
        return process.env.VUE_APP_BASE_STORE_APP_URL + '#/r?sid=' + String(this.order.reference);
      }
    }
  };
</script>
