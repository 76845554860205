<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="80%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.cancel.title') }} {{ order.name }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.cancel.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="note" :counter="noteMaxLength" required :rules="noteRules" :label="$t('order.form.cancel.input.note.label')" rows="2" :autofocus="true" :clearable="true" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('order.form.cancel.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && cancelOrder()">
              {{ $t('order.form.cancel.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'CancelOrderForm',
    props: {
      order: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        dialog: false,
        note: '',
        noteMinLength: 10,
        noteMaxLength: 500,
        noteRules: [
          v => !!v || this.$t('order.form.cancel.input.note.rule.required'),
          v => (v && v.length >= this.noteMinLength) || this.$t('order.form.cancel.input.note.rule.minLength', { count: this.noteMinLength }),
          v => (v && v.length <= this.noteMaxLength) || this.$t('order.form.cancel.input.note.rule.maxLength', { count: this.noteMaxLength })
        ],
        valid: true,
        loading: false
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        set (value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      async cancelOrder () {
        if (this.loading) {
          return false;
        }

        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/cancelled', { orderId: this.order.id, note: this.note });
          this.$emit('success', this.order.id);
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
