let speakerIsAvailable = true;
export const ring = (sound = 1) => {
  window.AudioContext = window.AudioContext || window.webkitAudioContext;
  if (typeof window.AudioContext === 'function') {
    const context = new window.AudioContext();
    if (context !== null && typeof context === 'object' && speakerIsAvailable && !isNaN(context.currentTime)) {
      speakerIsAvailable = false;
      const o = context.createOscillator();
      o.type = 'sine';
      o.frequency.setValueAtTime(sound * 600, context.currentTime); // value in hertz
      o.detune.setValueAtTime(100 / sound, context.currentTime); // value in cents
      o.connect(context.destination);
      o.start(context.currentTime);
      o.stop(context.currentTime + 0.6);
      o.onended = () => {
        speakerIsAvailable = true;
        o.disconnect();
        context.close();
      };
    }
  }

  if (window.navigator !== null && typeof window.navigator === 'object' && typeof window.navigator.vibrate === 'function') {
    window.navigator.vibrate(100);
  }
};
