export const areSimilar = (array1, array2) => { // TODO deprecated
  const ids1 = array1.map((obj) => obj.id);
  const ids2 = array2.map((obj) => obj.id);
  if (ids1.length !== ids2.length) {
    return false;
  }

  ids1.sort();
  ids2.sort();
  let areSimilar = true;
  for (let i = 0; i < ids1.length; i++) {
    if (ids1[i] !== ids2[i]) {
      areSimilar = false;
    }
  }

  return areSimilar;
};

export const hasNewElement = (array1, array2) => {
  const ids1 = array1.map((obj) => obj.id);
  const ids2 = array2.map((obj) => obj.id);

  let hasNewElement = false;
  for (let i = 0; i < ids2.length; i++) {
    if (!ids1.includes(ids2[i])) {
      hasNewElement = true;
    }
  }

  return hasNewElement;
};
