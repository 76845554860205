<template>
  <v-container id="production-events">
    <h3>{{ $t('customerService.productionEvents.subtitle') }}</h3>
    <br>
    <p v-for="content in $t('customerService.productionEvents.description')" :key="content">
      {{ content }}
    </p>
    <advanced-table :title="title" :headers="headers" :endpoint="endpoint" :transform="transform" :actions="actions" :colors="colors" :loading="loading || forceLoading" :refresh="refresh + forceRefresh"
                    :feature-period="false" :feature-search="false" :feature-exporter="false" :feature-printer="false" @success="success" @fail="fail" @action="action"
    />
    <p class="text-center">
      <v-btn medium color="primary" :loading="loading || forceLoading" :disabled="loading || forceLoading" @click="refreshProductionEvents()">
        {{ $t('customerService.productionEvents.button.refresh') }}
      </v-btn>
    </p>
  </v-container>
</template>

<script>
  import AdvancedTable from '@/views/components/common/AdvancedTable';
  import { phoneInt } from '@/util/PhoneUtil';
  import { DateTime } from 'luxon';

  export default {
    name: 'ProductionEvents',
    components: { AdvancedTable },
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        title: this.$t('customerService.productionEvents.title'),
        headers: [
          {
            text: this.$i18n.t('customerService.productionEvents.column.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'locationName'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.name'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'name'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.description'),
            align: 'center',
            filterable: true,
            sortable: false,
            value: 'description'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.delay'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'delay'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.order'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'orderName'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.user'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'user'
          },
          {
            text: this.$i18n.t('customerService.productionEvents.column.supervisor'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'supervisor'
          }
        ],
        endpoint: 'meta/production/events/active',
        transform: {
          orderName: (orderName) => '<a href="' + this.$router.resolve('/search').href + '?name=' + encodeURIComponent(orderName) + '">' + String(orderName) + '</a>',
          user: (user) => '<a href="tel:' + phoneInt(String(user.phoneNumber)) + '">' + (user.firstName ?? '') + ' ' + (user.lastName ?? '') + '</a>',
          supervisor: (user) => typeof user === 'undefined' ? undefined : (user.firstName ?? '')
        },
        actions: {
          supervisor: { name: 'supervisor', text: this.$t('customerService.productionEvents.button.supervise') }
        },
        colors: (item) => {
          if (item.createdAt <= DateTime.utc().minus({ minutes: 10 }).toISO()) {
            return 'alert';
          }
          if (item.createdAt <= DateTime.utc().minus({ minutes: 5 }).toISO()) {
            return 'warning';
          }

          return '';
        },
        forceRefresh: 0,
        forceLoading: false
      };
    },
    methods: {
      success (results) {
        this.$emit('count', results.length);
        this.$emit('success');
        this.forceRefresh = 0;
      },
      fail (error, message) {
        this.$emit('fail', error, message);
      },
      async action (name, item) {
        switch (name) {
          case 'supervisor': {
            this.forceLoading = true;
            try {
              await this.$http.post(process.env.VUE_APP_BASE_API_URL + 'meta/production/event/supervise', {
                id: item.id
              });
              this.$emit('success');
              this.forceRefresh = Date.now();
            } catch (error) {
              this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
            }
            this.forceLoading = false;
            break;
          }
        }
      },
      async refreshProductionEvents () {
        this.forceLoading = true;
        for (const loc of this.$store.state.locations) {
          try {
            await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'cli/production/event/detect?location=' + String(loc.key));
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
          }
        }
        this.$emit('success');
        this.forceRefresh = Date.now();
        this.forceLoading = false;
      }
    }
  };
</script>
