<template>
  <v-row justify="center">
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.editOrderDriver.title') }} {{ order.name }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.editOrderDriver.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && editOrderDriver()">
          <v-container class="edit-order-customer">
            <v-card color="editOrderTabBackgroundColor" class="main-card">
              <v-row>
                <v-col cols="4">
                  <v-select v-model="driverId" :items="drivers" item-value="id" :loading="loading" :disabled="loading" :label="$t('order.form.editOrderDriver.input.driver.label')" required dense hide-details>
                    <template slot="selection" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('order.form.editOrderDriver.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && editOrderDriver()">
              {{ $t('order.form.editOrderDriver.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import OrderState from '@/services/order/OrderState';

  export default {
    name: 'EditOrderDriverForm',
    props: {
      order: {
        type: Object,
        required: true
      },
      value: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        valid: true,
        loading: false,
        driverId: undefined,
        drivers: []
      };
    },
    computed: {
      show: {
        get () {
          return this.value;
        },
        async set (value) {
          if (value) {
            await this.refreshData();
          }
          this.$emit('input', value);
        }
      }
    },
    watch: {
      order: async function () {
        this.setDriverId();
        await this.refreshData();
      }
    },
    async mounted () {
      this.setDriverId();
      await this.refreshData();
    },
    methods: {
      setDriverId () {
        if ([OrderState.delivery, OrderState.next_delivery, OrderState.arriving].includes(this.order.state.state)) {
          this.driverId = this.order.state.user?.id;
        }
      },
      async editOrderDriver () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/driver',
                               {
                                 orderId: this.order.id,
                                 driverId: this.driverId
                               });
          this.$emit('success', this.order.id);
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      async refreshData () {
        if (this.order === undefined || this.drivers.length > 0) {
          return;
        }

        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'users/drivers?location=' + this.order.location.key, { timeout: 10000 });
          if (response.data !== undefined && response.data.users !== undefined) {
            this.drivers = response.data.users;
          }
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
