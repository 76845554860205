var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"recall-customers","fluid":"","tag":"section"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.recallCustomers.subtitle'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.calls,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.phone))}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('customerService.recallCustomers.state.' + item.state))+" ")]}},{key:"item.startedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startedAt))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatAction(item.phone))}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }