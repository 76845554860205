var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"recurrent-customers-platform","fluid":"","tag":"section"}},[_c('v-row',[_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.$t('customerService.recurrentCustomers.subtitle'))+" ")]),_vm._l((_vm.$t('customerService.recurrentCustomers.description')),function(content){return _c('div',{key:content},[_vm._v(" "+_vm._s(content)+" ")])})],2)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.recurrentCustomers.table.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.customers,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"vertical-align":"middle"},attrs:{"src":require('@/assets/provider/' + item.provider + '.png'),"width":"24px"}}),_vm._v(" "+_vm._s(_vm.$t('order.tab.details.provider.' + item.provider))+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.phone))}})]}},{key:"item.lastOrderState",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('order.tab.details.state.' + item.lastOrderState))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","rounded":"","color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.callRecurrentCustomer(item)}}},[_c('v-icon',{attrs:{"medium":"","dark":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }