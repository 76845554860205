<template>
  <div class="orders-view">
    <alert-notification :message="alert" />
    <v-card color="ordersTabBackgroundColor" class="main-card">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows height="20" :hide-slider="!header">
        <v-tab v-show="header">
          {{ $t('order.subtab.list') }}
        </v-tab>
        <v-tab v-show="header">
          {{ $t('order.subtab.map') }}
        </v-tab>
        <v-tab-item key="1">
          <v-card color="ordersTabBackgroundColor" class="tab-card">
            <v-row v-if="lastUpdate === 0">
              <v-col cols="12">
                <v-progress-linear color="primary" indeterminate rounded height="10" />
              </v-col>
            </v-row>
            <div v-else>
              <page-timer :count="showOrderCounter ? ordersList.length : 0" :last-update="lastUpdate" />
              <empty-order-list v-if="ordersList.length === 0" :title="noResultTitle" :description="noResultDescription" @refresh="refresh" />
              <div v-else-if="mode === 'delivery'">
                <order-delivery-tour :orders="ordersList" @refresh="refresh" @error="setAlert" />
              </div>
              <div v-else-if="mode === 'grouping'">
                <div class="pt-1 text-center">
                  <span v-for="g in Object.keys(groups)" :key="'title-' + g" class="px-2">
                    <a class="black--text d-inline-block" @click="scroll('group-' + g)">{{ $tc('order.list.group.' + g, groups[g].length, { count: groups[g].length }) }}</a>
                  </span>
                </div>
                <div v-for="g in Object.keys(groups)" :key="'content-' + g">
                  <v-row v-if="groups[g].length > 0" align="end" class="order-group-divider">
                    <v-col cols="3" sm="3" md="4" lg="5" xl="5" />
                    <v-col cols="6" sm="6" md="4" lg="2" xl="2">
                      <h4 :id="'group-' + g" :ref="'group-' + g">
                        {{ $tc('order.list.group.' + g, groups[g].length, { count: groups[g].length }) }}
                      </h4>
                      <v-divider />
                    </v-col>
                    <v-col cols="3" sm="3" md="4" lg="5" xl="5" />
                  </v-row>
                  <v-row>
                    <v-col v-for="order in groups[g]" :key="order.id" cols="12" sm="6" md="4" lg="3" xl="3" class="px-1 py-0">
                      <order-card :order="order" @refresh="refresh" @error="setAlert" />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-else>
                <v-row>
                  <v-col v-for="order in ordersList" :key="order.id" cols="12" sm="6" md="4" :lg="Math.max(12 / Math.max(ordersList.length, 3), 3)" :xl="Math.max(12 / Math.max(ordersList.length, 3), 3)" class="px-1 py-0">
                    <order-card :order="order" @refresh="refresh" @error="setAlert" />
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item key="2">
          <v-card color="ordersTabBackgroundColor" class="tab-card">
            <orders-map :orders="ordersList" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
  import { hasNewElement } from '@/util/ArrayUtil';
  import { ring } from '@/util/SoundUtil';
  import { isOnline, online, offline, isHidden, visibility } from '@/util/DeviceUtil';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import PageTimer from '@/views/components/common/PageTimer';
  import EmptyOrderList from '@/views/components/business/order/EmptyOrderList';
  import OrdersMap from '@/views/components/business/order/OrdersMap';
  import OrderCard from '@/views/components/business/order/OrderCard';
  import OrderDeliveryTour from '@/views/components/business/order/OrderDeliveryTour';

  export default {
    name: 'OrdersView',
    components: { AlertNotification, EmptyOrderList, PageTimer, OrderDeliveryTour, OrdersMap, OrderCard },
    props: {
      error: {
        type: String,
        required: true
      },
      header: {
        type: Boolean,
        default: true
      },
      mode: {
        type: String,
        default: 'grouping'
      },
      lastUpdate: {
        type: Number,
        required: true
      },
      orders: {
        type: Array,
        required: true
      },
      showOrderCounter: {
        type: Boolean,
        default: true
      },
      noResultTitle: {
        type: String,
        required: true
      },
      noResultDescription: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        alert: '',
        groups: {},
        ordersList: [],
        resfreshTimeout: null
      };
    },
    watch: {
      error: function (message) {
        this.alert = message;
      },
      orders: function () {
        this.pushNotification();
        this.ordersList = this.orders;
        this.groups = {};
        this.ordersList.forEach((order) => {
          const state = String(order.state === undefined ? 'unknown' : order.state.state);
          if (this.groups[state] === undefined) {
            this.groups[state] = [];
          }
          this.groups[state].push(order);
        });
      }
    },
    mounted: function () {
      this.refresh();
      online(this.online, true);
      offline(this.offline, true);
      visibility(this.visibility, true);
    },
    destroyed: function () {
      this.cancelRefreshTimeout();
      online(this.online, false);
      offline(this.offline, false);
      visibility(this.visibility, false);
    },
    methods: {
      scroll (id) {
        const element = document.getElementById(id);
        if (element !== null) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
      cancelRefreshTimeout () {
        if (this.resfreshTimeout !== null) {
          clearInterval(this.resfreshTimeout);
          this.resfreshTimeout = null;
        }
      },
      online () {
        this.refresh();
      },
      offline () {
        this.alert = this.$i18n.t('common.error.offline');
      },
      pushNotification () {
        if (hasNewElement(this.ordersList, this.orders)) {
          const previousTitle = document.title;
          if (!previousTitle.includes('🚨')) {
            let counter = 0;
            const notification = function () {
              if (counter >= 4) {
                document.title = previousTitle;
                clearInterval(timer);
              } else if (counter % 2 === 0) {
                document.title = '🚨 🍔 🚨 🍔 🚨';
              } else {
                document.title = '🚨 NOUVELLE(S) COMMANDE(S) | ' + previousTitle;
              }
              counter++;
            };
            const timer = setInterval(notification, 1000);
          }
          ring(1);
        }
      },
      refresh () {
        this.cancelRefreshTimeout();

        if (isOnline() && !isHidden()) {
          this.alert = '';
          this.$emit('refresh');
        } else {
          this.offline();
        }

        this.resfreshTimeout = setTimeout(() => {
          this.refresh();
        }, 15000);
      },
      setAlert (message) {
        this.alert = message;
      },
      visibility () {
        if (!isHidden()) {
          this.refresh();
        }
      }
    }
  };
</script>
