<template>
  <div />
</template>

<script>
  import { getPageName } from '@/services/common/Http';
  import { isHidden, visibility } from '@/util/DeviceUtil';

  export default {
    name: 'PageTimer',
    props: {
      count: {
        type: Number,
        default: 1
      },
      lastUpdate: {
        type: Number,
        default: Date.now()
      }
    },
    watch: {
      lastUpdate: async function () {
        await this.refreshTimer();
      }
    },
    mounted: async function () {
      this.setInfo(undefined);
      await this.refreshTimer();
      visibility(this.visibility, true);
    },
    destroyed: async function () {
      this.setInfo(undefined);
      this.cancelRefreshTimerTimeout();
      visibility(this.visibility, false);
    },
    methods: {
      cancelRefreshTimerTimeout () {
        if (this.resfreshTimerTimeout !== undefined) {
          clearInterval(this.resfreshTimerTimeout);
        }
      },
      async refreshTimer () {
        this.cancelRefreshTimerTimeout();
        if (!isHidden()) {
          const delay = String(parseInt((Date.now() - this.lastUpdate) / 1000));
          let info = '';
          if (this.count > 0) {
            info += this.$i18n.tc('order.timer.countOrders', this.count, { count: this.count }) + ' | ';
          }
          this.setInfo(info + this.$i18n.t('order.timer.updated', { delay: delay }));
        }

        this.resfreshTimerTimeout = setTimeout(async () => {
          await this.refreshTimer();
        }, 5000);
      },
      visibility () {
        if (!isHidden()) {
          this.refreshTimer();
        }
      },
      setInfo (info) {
        this.$store.commit('SET_PAGE_INFO_LINE1', {
          pageName: getPageName(),
          value: info
        });
      }
    }
  };
</script>
