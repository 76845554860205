<template>
  <v-container id="partial-orders" fluid tag="section">
    <orders-view :header="false" :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('order.list.empty.title')" :no-result-description="$t('order.list.empty.description')"
                 mode="list" :show-order-counter="false" @refresh="refreshOrders"
    />
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'PartialOrders',
    components: { OrdersView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: []
      };
    },
    methods: {
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/partial');
          this.orders = response.data;
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('order.error'));
        }
        this.$emit('count', this.orders.length);
      }
    }
  };
</script>
