<template>
  <v-container id="edit-order-detail" class="m-0 pa-0">
    <v-btn text small rounded color="grey" :loading="show || loading" :disabled="show || loading" @click.stop="refreshSlots(),show=true">
      <v-icon left>
        mdi-pencil
      </v-icon>{{ $t('order.button.editOrderDetail') }}
    </v-btn>
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.editOrderDetail.title') }} {{ order.name }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.editOrderDetail.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation @keyup.native.enter="validate() && editOrderActionDatesDetail()">
          <v-container class="edit-order-customer" style="max-width: 1200px">
            <v-row class="text-center align-center">
              <v-col cols="12" sm="6" md="2">
                <label for="input-preparation-date">{{ $t('order.form.editOrderDetail.input.preparationDate.label') }}</label>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select id="input-preparation-date" v-model="preparationDate" :input-value="preparationDate" :items="preparationSlots" item-value="date" item-text="text" :loading="loading"
                          required dense hide-details style="width:95%" @change="preparationDateIsUpdated()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <label for="input-target-date">{{ targetDateLabel }}</label>
              </v-col>
              <v-col cols="12" sm="6" md="2">
                <v-select id="input-target-date" v-model="targetDate" :input-value="targetDate" :items="targetSlots" item-value="date" item-text="text" :loading="loading"
                          required dense hide-details style="width:95%" @change="targetDateIsUpdated()"
                />
              </v-col>
              <v-col cols="12" sm="4" md="1" />
              <v-col cols="12" sm="8" md="3">
                <v-checkbox id="input-linked" v-model="linked" :loading="loading" required dense hide-details class="ma-0 pa-0" :label="$t('order.form.editOrderDetail.input.linked')" />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('order.form.editOrderDetail.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading || preparationDate === undefined || targetDate === undefined" :loading="loading" color="success" @click.stop="validate() && editOrderActionDatesDetail()">
              {{ $t('order.form.editOrderDetail.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { DateTime } from 'luxon';

  export default {
    name: 'EditOrderDetailForm',
    props: {
      order: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        linked: false,
        preparationDate: undefined,
        previousPreparationDate: undefined,
        targetDateLabel: undefined,
        targetDate: undefined,
        previousTargetDate: undefined,
        preparationSlots: [],
        targetSlots: [],
        show: false,
        valid: true,
        loading: false
      };
    },
    mounted: async function () {
      this.preparationDate = DateTime.fromISO(this.order.actions?.preparation?.date).toUTC().toISO();
      this.previousPreparationDate = this.preparationDate;

      if (this.order.actions.delivery !== undefined) {
        this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.delivery');
        this.targetDate = DateTime.fromISO(this.order.actions.delivery.date).toUTC().toISO();
      } else if (this.order.actions.onsite !== undefined) {
        this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.onsite');
        this.targetDate = DateTime.fromISO(this.order.actions.onsite.date).toUTC().toISO();
      } else if (this.order.actions.pickup !== undefined) {
        this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.pickup');
        this.targetDate = DateTime.fromISO(this.order.actions.pickup.date).toUTC().toISO();
      } else if (this.order.actions.shipping !== undefined) {
        this.targetDateLabel = this.$i18n.t('order.form.editOrderDetail.input.targetDate.label.shipping');
        this.targetDate = DateTime.fromISO(this.order.actions.shipping.date).toUTC().toISO();
      }
      this.previousTargetDate = this.targetDate;
    },
    methods: {
      preparationDateIsUpdated () {
        if (this.linked && this.preparationDate !== undefined && this.previousPreparationDate !== undefined && this.preparationDate !== this.previousPreparationDate) {
          const diff = DateTime.fromISO(this.preparationDate).diff(DateTime.fromISO(this.previousPreparationDate));
          this.targetDate = DateTime.fromISO(this.targetDate).plus({ minutes: diff.toFormat('m') }).toUTC().toISO();
        }
        this.previousPreparationDate = this.preparationDate;
      },
      targetDateIsUpdated () {
        if (this.linked && this.targetDate !== undefined && this.previousTargetDate !== undefined && this.targetDate !== this.previousTargetDate) {
          const diff = DateTime.fromISO(this.targetDate).diff(DateTime.fromISO(this.previousTargetDate));
          this.preparationDate = DateTime.fromISO(this.preparationDate).plus({ minutes: diff.toFormat('m') }).toUTC().toISO();
        }
        this.previousTargetDate = this.targetDate;
      },
      async refreshSlots () {
        if (this.preparationSlots.length === 0 && this.targetSlots.length === 0) {
          try {
            const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'slots?location=' + this.order.location?.key);
            if (response.data !== undefined && response.data.preparation !== undefined && Array.isArray(response.data.preparation)
              && response.data.target !== undefined && Array.isArray(response.data.target)) {
              const fromDate = DateTime.utc().minus({ minutes: 15 }).toISO();
              this.preparationSlots = response.data.preparation.filter((preparation) => preparation.date >= fromDate);
              this.targetSlots = response.data.target.filter((target) => target.date >= fromDate);
            }
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('order.error'));
          }
        }
      },
      async editOrderActionDatesDetail () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/action/date', {
            orderId: this.order.id,
            preparationDate: (this.preparationDate === undefined ? '' : this.preparationDate),
            targetDate: (this.targetDate === undefined ? '' : this.targetDate)
          });
          this.$emit('success', this.order.id);
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('order.error'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
