<template>
  <v-row>
    <v-col cols="12">
      <section v-if="title" class="text-center">
        <h1 class="font-weight-light mt-8 mb-2 headline" v-text="title" />
      </section>
      <v-row v-if="description">
        <v-col cols="12">
          <v-card class="pa-5">
            <v-card-text class="text-center">
              <base-subheading class="text-center" :subheading="description" />
              <v-btn color="secondary" x-large :disabled="loading" :loading="loading" @click.stop="refresh()">
                {{ $t('common.button.refresh') }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'EmptyOrderList',
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        loading: false
      };
    },
    methods: {
      refresh () {
        this.loading = true;
        this.$emit('refresh');
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    }
  };
</script>
