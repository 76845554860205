<template>
  <v-container id="close-order-abandoned" class="m-0 pa-0">
    <v-btn small rounded color="primary" :loading="loading" @click.stop="show=true">
      <v-icon medium dark>
        mdi-check
      </v-icon>
    </v-btn>
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <base-material-card class="mt-6">
        <template #heading>
          <div class="display-2 font-weight-light text-uppercase">
            {{ $t('order.form.closeOrderAbandoned.title') }}
          </div>
          <div class="subtitle-1 font-weight-light">
            {{ $t('order.form.closeOrderAbandoned.subtitle') }}
          </div>
        </template>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container style="max-width: 1200px">
            <v-row>
              <v-col cols="12" md="4">
                {{ $t('order.form.closeOrderAbandoned.input.note.label') }}
              </v-col>
              <v-col cols="12" md="8">
                <v-textarea v-model="note" :counter="noteMaxLength" :rules="noteRules" required
                            :label="$t('order.form.closeOrderAbandoned.input.note.label')" :clearable="true"
                            dense auto-grow rows="4" row-height="15"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer />
            <v-btn color="close" @click.stop="show=false">
              {{ $t('order.form.closeOrderAbandoned.button.close') }}
            </v-btn>
            <v-btn :disabled="!valid || loading" :loading="loading" color="success" @click.stop="validate() && closeOrderAbandoned()">
              {{ $t('order.form.closeOrderAbandoned.button.save') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </base-material-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'CloseOrderAbandonedForm',
    props: {
      orderAbandoned: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        note: '',
        noteMinLength: 10,
        noteMaxLength: 5000,
        noteRules: [
          v => !!v || this.$t('order.form.closeOrderAbandoned.input.note.rule.required'),
          v => (v && v.length >= this.noteMinLength) || this.$t('order.form.closeOrderAbandoned.input.note.rule.minLength', { count: this.noteMinLength }),
          v => (v && v.length <= this.noteMaxLength) || this.$t('order.form.closeOrderAbandoned.input.note.rule.maxLength', { count: this.noteMaxLength })
        ],
        show: false,
        valid: true,
        loading: false
      };
    },
    methods: {
      async closeOrderAbandoned () {
        this.loading = true;
        try {
          await this.$http.put(process.env.VUE_APP_BASE_API_URL + 'order/abandoned/close', { id: this.orderAbandoned.id, note: this.note });
          this.$emit('success');
          this.show = false;
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
      },
      validate () {
        return this.$refs.form.validate();
      }
    }
  };
</script>
