<template>
  <v-row dense class="text-center" justify="center">
    <v-col cols="12" sm="6" lg="2">
      <v-dialog ref="dialog" v-model="startDateSelector" persistent width="290px">
        <template #activator="{ on, attrs }">
          <v-text-field v-model="startDateFormatted" :label="$t('common.period.startDate')" :disabled="loading" :loading="loading" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="startDate" no-title first-day-of-week="1" :header-date-format="getMonthFormat" :month-format="getMonthFormat" :weekday-format="getWeekdayFormat" :show-week="true"
                       :disabled="loading" :loading="loading" @input="startDateSelector = false"
        />
      </v-dialog>
    </v-col>
    <v-col cols="12" sm="6" lg="2">
      <v-dialog ref="dialog" v-model="startHourSelector" persistent width="290px">
        <template #activator="{ on, attrs }">
          <v-text-field v-model="startHourFormatted" :label="$t('common.period.startHour')" :disabled="loading" :loading="loading"
                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
          />
        </template>
        <v-time-picker v-model="startHour" :disabled="loading" :loading="loading" full-width format="24hr" @input="startHourSelector = false" />
      </v-dialog>
    </v-col>
    <v-col cols="12" sm="6" lg="2">
      <v-dialog ref="dialog" v-model="endDateSelector" persistent width="290px">
        <template #activator="{ on, attrs }">
          <v-text-field v-model="endDateFormatted" :label="$t('common.period.endDate')" :disabled="loading" :loading="loading" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="endDate" no-title first-day-of-week="1" :header-date-format="getMonthFormat" :month-format="getMonthFormat" :weekday-format="getWeekdayFormat" :show-week="true"
                       :disabled="loading" :loading="loading" @input="endDateSelector = false"
        />
      </v-dialog>
    </v-col>
    <v-col cols="12" sm="6" lg="2">
      <v-dialog ref="dialog" v-model="endHourSelector" persistent width="290px">
        <template #activator="{ on, attrs }">
          <v-text-field v-model="endHourFormatted" :label="$t('common.period.endHour')" :disabled="loading" :loading="loading"
                        prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
          />
        </template>
        <v-time-picker v-model="endHour" :disabled="loading" :loading="loading" full-width format="24hr" @input="endHourSelector = false" />
      </v-dialog>
    </v-col>
    <v-col v-if="submit==='manual'" cols="12" sm="12" lg="2">
      <v-btn big color="senary" :loading="loading" @click.stop="update(true)">
        {{ $t('common.period.submit') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import { DateTime } from 'luxon';
  import { MONTH, WEEKDAY_SHORT, getTodayDate } from '@/util/DateUtil';

  export default {
    name: 'ProductsSoldAnalytics',
    props: {
      startDateProp: {
        type: String,
        required: true
      },
      startHourProp: {
        type: String,
        required: true
      },
      endDateProp: {
        type: String,
        required: true
      },
      endHourProp: {
        type: String,
        required: true
      },
      maxDays: {
        type: Number,
        default: 120
      },
      loading: {
        type: Boolean,
        default: false
      },
      submit: {
        type: String,
        default: 'auto'
      }
    },
    data () {
      return {
        startDate: getTodayDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startDateSelector: false,
        startHourSelector: false,
        endDateSelector: false,
        endHourSelector: false,
        startFullDate: null,
        endFullDate: null
      };
    },
    computed: {
      startDateFormatted () {
        return this.formatDate(this.startDate);
      },
      startHourFormatted () {
        return this.formatHour(this.startHour);
      },
      endDateFormatted () {
        return this.formatDate(this.endDate);
      },
      endHourFormatted () {
        return this.formatHour(this.endHour);
      }
    },
    watch: {
      startDate: async function () {
        this.sortDates();
        this.maxEndDate();
        if (this.submit === 'auto') {
          this.update();
        }
      },
      startHour: async function () {
        this.sortHours();
        if (this.submit === 'auto') {
          this.update();
        }
      },
      endDate: async function () {
        this.sortDates();
        this.minStartDate();
        if (this.submit === 'auto') {
          this.update();
        }
      },
      endHour: async function () {
        this.sortHours();
        if (this.submit === 'auto') {
          this.update();
        }
      }
    },
    mounted: async function () {
      this.startDate = this.startDateProp;
      this.startHour = this.startHourProp;
      this.endDate = this.endDateProp;
      this.endHour = this.endHourProp;
      if (this.submit === 'auto') {
        this.update();
      }
    },
    methods: {
      update (force) {
        const startFullDate = DateTime.fromISO(this.startDate + 'T' + this.startHour + ':00', { zone: 'Europe/Paris' }).toISO();
        const endFullDate = DateTime.fromISO(this.endDate + 'T' + this.endHour + ':59', { zone: 'Europe/Paris' }).toISO();
        if (force || startFullDate !== this.startFullDate || endFullDate !== this.endFullDate) {
          this.startFullDate = startFullDate;
          this.endFullDate = endFullDate;
          this.$emit('update', this.startDate, this.startHour, this.endDate, this.endHour, this.startFullDate, this.endFullDate);
        }
      },
      sortDates () {
        if (this.startDate > this.endDate) {
          const tmp = this.startDate;
          this.startDate = this.endDate;
          this.endDate = tmp;
        }
      },
      sortHours () {
        if (this.startDate === this.endDate && this.startHour > this.endHour) {
          const tmp = this.startHour;
          this.startHour = this.endHour;
          this.endHour = tmp;
        }
      },
      minStartDate () {
        const minEndDate = DateTime.fromISO(this.endDate, { setZone: true }).minus({ days: this.maxDays }).toISODate();
        if (this.startDate < minEndDate) {
          this.startDate = minEndDate;
        }
      },
      maxEndDate () {
        const maxEndDate = DateTime.fromISO(this.startDate, { setZone: true }).plus({ days: this.maxDays }).toISODate();
        if (this.endDate > maxEndDate) {
          this.endDate = maxEndDate;
        }
      },
      formatDate (date) {
        if (!date) {
          return undefined;
        }

        return DateTime.fromISO(date, { setZone: true }).toLocaleString({ locale: 'fr-fr' });
      },
      formatHour (hour) {
        if (!hour) {
          return undefined;
        }

        return hour.replace(':', 'h');
      },
      getMonthFormat (date) {
        const dateTime = DateTime.fromISO(date, { setZone: true });
        return MONTH[dateTime.month] + ' ' + dateTime.year;
      },
      getWeekdayFormat (date) {
        const weekdayId = DateTime.fromISO(date, { setZone: true }).weekday;
        return WEEKDAY_SHORT[weekdayId];
      }
    }
  };
</script>
