var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"late-orders","fluid":"","tag":"section"}},[_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.$t('customerService.lateOrders.subtitle'))+" ")]),_vm._l((_vm.$t('customerService.lateOrders.description')),function(content){return _c('div',{key:content},[_vm._v(" "+_vm._s(content)+" ")])})],2),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.lateOrders.table.todo.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.todoHeaders,"options":_vm.tableOptions,"items":_vm.todoOrders,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayOrderName(item))}})]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.firstName)+" "+_vm._s(item.customer.lastName)+" ")]}},{key:"item.customerPhone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.customer.phone))}})]}},{key:"item.customerPhone2",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.customer.phone2))}})]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayDriver(item))}})]}},{key:"item.delay",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayDelay(item))}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayState(item))}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","rounded":"","color":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.callLateOrderCustomer(item)}}},[_c('v-icon',{attrs:{"medium":"","dark":""}},[_vm._v(" mdi-check ")])],1)]}}],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"12"}},[_c('v-card',{staticClass:"ma-0"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('customerService.lateOrders.table.done.title'))+" ")]),_c('v-data-table',{attrs:{"headers":_vm.doneHeaders,"options":_vm.tableOptions,"items":_vm.doneOrders,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayOrderName(item))}})]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.firstName)+" "+_vm._s(item.customer.lastName)+" ")]}},{key:"item.customerPhone",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.customer.phone))}})]}},{key:"item.customerPhone2",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.formatPhone(item.customer.phone2))}})]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayDriver(item))}})]}},{key:"item.delay",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayDelay(item))}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayState(item))}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }