<template>
  <span>{{ formattedTimeLeft }}</span>
</template>

<script>
  import { DateTime } from 'luxon';
  import { secondToHHmm } from '@/util/DateUtil';
  const LIMIT_ALERT_IN_SECONDS = (1 * 60) - 1;
  const LIMIT_WARNING_IN_SECONDS = (5 * 60) - 1;

  const TimerType = {
    ALERT: 'alert',
    WARNING: 'warning',
    INFO: 'info'
  };

  export default {
    name: 'OrderCountdown',
    props: {
      targetDate: {
        type: String,
        required: true
      },
      refresh: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        timerType: 'info',
        timeLeftInSeconds: 0
      };
    },
    computed: {
      formattedTimeLeft () {
        let sign = '+';
        if (this.timeLeftInSeconds < -59) {
          sign = '-';
        } else if (this.timeLeftInSeconds > -60 && this.timeLeftInSeconds < 60) {
          sign = '';
        }
        return '[' + sign + secondToHHmm(this.timeLeftInSeconds) + ']';
      }
    },
    watch: {
      refresh: function () {
        this.updateTimer();
      }
    },
    methods: {
      updateTimer () {
        const now = DateTime.utc().toSeconds();
        const target = DateTime.fromISO(this.targetDate).setZone('UTC').toSeconds();
        this.timeLeftInSeconds = target - now;

        let newTimerType = '';
        if (this.timeLeftInSeconds < LIMIT_ALERT_IN_SECONDS) {
          newTimerType = TimerType.ALERT;
        } else if (this.timeLeftInSeconds <= LIMIT_WARNING_IN_SECONDS) {
          newTimerType = TimerType.WARNING;
        } else {
          newTimerType = TimerType.INFO;
        }

        if (newTimerType !== this.timerType) {
          this.timerType = newTimerType;
          this.$emit('updated-timer-type', newTimerType);
        }
      }
    }
  };
</script>
