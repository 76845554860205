import { DateTime } from 'luxon';
import i18n from '@/i18n';

export const MONTH = ['', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
export const WEEKDAY_SHORT = ['', 'L', 'M', 'M', 'J', 'V', 'S', 'D'];
export const WEEKDAY = ['', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

export const displayISO = (isoDate, locale = 'fr', short = true) => {
  if (isoDate === undefined) {
    return '';
  }

  if (short && isToday(isoDate)) {
    return displayHourISO(isoDate);
  } else {
    const date = DateTime.fromISO(isoDate, { setZone: true, locale: locale }).setZone('Europe/Paris');
    const part1 = date.toFormat('d MMM yyyy');
    const part2 = date.toFormat('H:mm').replace(':', i18n.t('common.time.short.hour'));
    return part1 + ' ' + i18n.t('common.time.at') + ' ' + part2;
  }
};

export const displayDateISO = (isoDate, locale = 'fr') => {
  if (isoDate === undefined) {
    return '';
  }

  return DateTime.fromISO(isoDate, { setZone: true }).setZone('Europe/Paris').toLocaleString({ locale: 'fr-fr' });
};

export const displayHourISO = (isoDate, locale = 'fr') => {
  if (isoDate === undefined) {
    return '';
  }

  return DateTime.fromISO(isoDate, { setZone: true }).setZone('Europe/Paris').toFormat('HH:mm').replace(':', i18n.t('common.time.short.hour'));
};

export const displayWeekday = (isoDate, locale = 'fr') => {
  if (isoDate === undefined) {
    return '';
  }

  return WEEKDAY[DateTime.fromISO(isoDate, { setZone: true }).setZone('Europe/Paris').weekday];
};

export const isToday = (isoDate) => {
  const todayDate = DateTime.utc().toFormat('yyyy-MM-dd');
  const todayStartDate = todayDate + ' 00:00:00';
  const todayEndDate = todayDate + ' 23:59:59';
  const date = DateTime.fromISO(isoDate, { setZone: true }).setZone('UTC').toFormat('yyyy-MM-dd HH:mm:ss');

  return date >= todayStartDate && date <= todayEndDate;
};

export const getFirstDayOfYearDate = (yearsAgo = 0) => {
  return String((new Date()).getFullYear() + yearsAgo) + '-01-01';
};

export const getFirstDayOfMonthDate = () => {
  const month = (new Date()).getMonth() + 1;

  return String((new Date()).getFullYear()) + '-' + (month < 10 ? '0' : '') + String(month) + '-01';
};

export const getTodayDate = () => {
  return (new Date()).toISOString().split('T')[0];
};

export const secondToHHmm = (seconds) => {
  if (seconds === undefined) {
    return '?';
  }

  const h = Math.floor(Math.abs(seconds) % (3600 * 24) / 3600);
  const m = Math.floor(Math.abs(seconds) % 3600 / 60);

  let output = '';
  if (h === 0) {
    output = String(m) + i18n.t('common.time.short.minute');
  } else {
    output = String(h) + i18n.t('common.time.short.hour') + to2Digits(m);
  }

  return output;
};

export const hhmmssToObject = (hhmmss) => {
  if (typeof hhmmss !== 'string') {
    return { hour: 0, minute: 0, second: 0 };
  }

  const parts = hhmmss.split(':');
  if (parts.length !== 3) {
    return { hour: 0, minute: 0, second: 0 };
  }

  return {
    hour: Number(parts[0]),
    minute: Number(parts[1]),
    second: Number(parts[2])
  };
};

export const to2Digits = (data) => {
  const nb = String(data);
  return nb.length === 1 ? '0' + nb : nb;
};
