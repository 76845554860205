<template>
  <v-container id="orders-abandoned" fluid tag="section">
    <v-row>
      <div class="pa-2">
        <div class="text-h3">
          {{ $t('customerService.ordersAbandoned.subtitle') }}
        </div>
        <div v-for="content in $t('customerService.ordersAbandoned.description')" :key="content">
          {{ content }}
        </div>
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.ordersAbandoned.table.title1') }}
          </v-card-title>
          <v-data-table :headers="headers1" :options="options" :items="ordersAbandoned" :loading="loading" :hide-default-footer="true">
            <template #[`item.provider`]="{ item }">
              <img :src="require('@/assets/provider/' + item.provider + '.png')" width="24px" style="vertical-align:middle">
              {{ $t('order.tab.details.provider.' + item.provider) }}
            </template>
            <template #[`item.customerPhone`]="{ item }">
              <span v-html="formatPhone(item.customerPhone)" />
            </template>
            <template #[`item.reason`]="{ item }">
              {{ $t('customerService.ordersAbandoned.table.reasons.' + item.reason) }}
            </template>
            <template #[`item.link`]="{ item }">
              <a v-if="item.link" :href="item.link" target="_blank">{{ $t('customerService.ordersAbandoned.button.pay') }}</a>
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template #[`item.action`]="{ item }">
              <close-order-abandoned-form :order-abandoned="item" @success="success" @fail="fail" />
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card class="ma-0">
          <v-card-title>
            {{ $t('customerService.ordersAbandoned.table.title2') }}
          </v-card-title>
          <v-data-table :headers="headers2" :options="options" :items="closedOrdersAbandoned" :loading="loading" :hide-default-footer="true">
            <template #[`item.provider`]="{ item }">
              <img :src="require('@/assets/provider/' + item.provider + '.png')" width="24px" style="vertical-align:middle">
              {{ $t('order.tab.details.provider.' + item.provider) }}
            </template>
            <template #[`item.reason`]="{ item }">
              {{ $t('customerService.ordersAbandoned.table.reasons.' + item.reason) }}
            </template>
            <template #[`item.link`]="{ item }">
              <a v-if="item.link" :href="item.link" target="_blank">{{ $t('customerService.ordersAbandoned.button.pay') }}</a>
            </template>
            <template #[`item.createdAt`]="{ item }">
              {{ formatDate(item.createdAt) }}
            </template>
            <template #[`item.updatedAt`]="{ item }">
              {{ formatDate(item.updatedAt) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { displayDateISO, displayHourISO } from '@/util/DateUtil';
  import { displayPhone, phoneInt } from '@/util/PhoneUtil';
  import CloseOrderAbandonedForm from '@/views/forms/meta/order/CloseOrderAbandonedForm';

  export default {
    name: 'OrdersAbandoned',
    components: { CloseOrderAbandonedForm },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        ordersAbandoned: [],
        closedOrdersAbandoned: [],
        loading: false,
        headers1: [
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'location.name'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.provider'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'provider'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.customerName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerName'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.customerPhone'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerPhone'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.reason'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'reason'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.link'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'link'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.date'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'createdAt'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.action'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'action'
          }
        ],
        headers2: [
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.locationName'),
            align: 'start',
            filterable: true,
            sortable: true,
            value: 'location.name'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.provider'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'provider'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.customerName'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'customerName'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.reason'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'reason'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.note'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'note'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.link'),
            align: 'center',
            filterable: false,
            sortable: false,
            value: 'link'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.createdAt'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'createdAt'
          },
          {
            text: this.$i18n.t('customerService.ordersAbandoned.table.updatedAt'),
            align: 'center',
            filterable: true,
            sortable: true,
            value: 'updatedAt'
          }
        ],
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['createdAt'],
          sortDesc: [true],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        }
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    methods: {
      formatDate (date) {
        return displayDateISO(date) + ' ' + this.$i18n.t('common.time.at') + ' ' + displayHourISO(date);
      },
      formatPhone (phone) {
        if (phone === undefined) {
          return '';
        }

        return '<a href="tel:' + phoneInt(phone) + '" target="_blank">' + displayPhone(phone) + '</a>';
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/abandoned/last');
          if (response.data !== undefined) {
            this.ordersAbandoned = response.data.open;
            this.closedOrdersAbandoned = response.data.closed;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('customerService.error.fail'));
        }
        this.loading = false;
        this.$emit('count', this.ordersAbandoned.length);
      },
      async success () {
        this.$emit('success');
        await this.refreshData();
      },
      async fail (error, message) {
        this.$emit('fail', error, message);
      }
    }
  };
</script>
