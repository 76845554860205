<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card id="table">
          <period-selector v-if="featurePeriod" :start-date-prop="startDate" :start-hour-prop="startHour" :end-date-prop="endDate" :end-hour-prop="endHour" :loading="mainLoading || secondaryLoading" @update="updatePeriod" />
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-text-field v-if="featureSearch" v-model="search" append-icon="mdi-magnify" :label="$t('common.table.search')" single-line hide-details />
            <v-spacer />
            <table-exporter v-if="featureExporter" :start-full-date="startFullDate" :end-full-date="endFullDate" :headers="headers" :items="results" />
            <table-printer v-if="featurePrinter" element-id="table" />
          </v-card-title>
          <v-data-table :headers="headers" :options="options" :items="results" :loading="mainLoading || secondaryLoading" :search="search" :item-class="formatRowBackground" hide-default-footer>
            <template v-for="header in headers.filter((h) => actions === null || !Object.keys(actions).includes(h.value))" #[getSlotName(header.value)]="{ item }">
              <span :key="header.value" v-html="item[header.value]" />
            </template>
            <template v-for="actionName in Object.keys(actions === null ? {} : actions)" #[getSlotName(actionName)]="{ item }">
              <span :key="actionName">
                <span v-if="item[actionName] !== undefined" v-html="item[actionName]" />
                <v-btn v-else medium dark color="primary" @click="action(actionName, item)">
                  {{ actions[actionName].text }}
                </v-btn>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getFirstDayOfMonthDate, getTodayDate } from '@/util/DateUtil';
  import PeriodSelector from '@/views/components/common/PeriodSelector';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'AdvancedTable',
    components: { PeriodSelector, TableExporter, TablePrinter },
    props: {
      title: {
        type: String,
        required: true
      },
      headers: {
        type: Array,
        required: true
      },
      endpoint: {
        type: String,
        required: true
      },
      transform: {
        type: Object,
        default: null
      },
      actions: {
        type: Object,
        default: null
      },
      colors: {
        type: Function,
        default: null
      },
      mainLoading: {
        type: Boolean,
        default: false
      },
      refresh: {
        type: Number
      },
      featurePeriod: {
        type: Boolean,
        default: true
      },
      featureSearch: {
        type: Boolean,
        default: true
      },
      featureExporter: {
        type: Boolean,
        default: true
      },
      featurePrinter: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        secondaryLoading: false,
        startDate: getFirstDayOfMonthDate(),
        startHour: '00:00',
        endDate: getTodayDate(),
        endHour: '23:59',
        startFullDate: null,
        endFullDate: null,
        search: '',
        options: {
          page: 1,
          itemsPerPage: -1,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        results: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      if (!this.featurePeriod) {
        this.secondaryLoading = true;
        await this.refreshData();
        this.secondaryLoading = false;
      }
    },
    methods: {
      formatRowBackground (item) {
        if (typeof this.colors === 'function') {
          return this.colors(item);
        }

        return '';
      },
      getSlotName (value) {
        return 'item.' + String(value);
      },
      async refreshData () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + this.endpoint
            + (this.featurePeriod ? '?from=' + encodeURIComponent(this.startFullDate) + '&to=' + encodeURIComponent(this.endFullDate) : ''), { timeout: 60000 });
          if (typeof response.data === 'object') {
            this.processResults(response.data.results);
          }
          this.$emit('success', this.results);
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('metaReport.error'));
        }
      },
      processResults (results) {
        if (!Array.isArray(results)) {
          return;
        }

        if (this.transform !== null) {
          results.forEach((result) => {
            Object.keys(result).forEach((key) => {
              if (typeof this.transform[key] === 'function') {
                result[key] = this.transform[key](result[key]);
              }
            });
          });
        }

        this.results = results;
      },
      action (name, item) {
        this.$emit('action', name, item);
      },
      async updatePeriod (startDate, startHour, endDate, endHour, startFullDate, endFullDate) {
        this.startDate = startDate;
        this.startHour = startHour;
        this.endDate = endDate;
        this.endHour = endHour;
        this.startFullDate = startFullDate;
        this.endFullDate = endFullDate;

        this.secondaryLoading = true;
        await this.refreshData();
        this.secondaryLoading = false;
      }
    }
  };
</script>
