<template>
  <v-container id="customer-support">
    <h3>{{ $t('customerService.support.title') }}</h3>
    <iframe :src="url" width="100%" height="700" />
  </v-container>
</template>
<script>
  export default {
    name: 'CustomerSupport',
    data () {
      return {
        loading: false,
        url: 'https://support.onylab.com/app/accounts/1/dashboard'
      };
    }
  };
</script>
