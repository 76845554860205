export const displayPhone = (phone) => {
  if (typeof phone !== 'string') {
    return phone;
  }

  if (phone.startsWith('+33') && phone.length === 12) {
    let i = 0;
    return '0' + phone.substring(3, 12).split('').map((num) => {
      return num + (i++ % 2 === 0 ? ' ' : '');
    }).join('').trim();
  }

  return phone.replace('#', '\n#');
};

export const phoneInt = (phone) => {
  if (typeof phone !== 'string') {
    return phone;
  }

  phone = phone.trim().replace(/\s?\.?-?\(?\)?/g, '');
  if (phone.startsWith('0')) {
    phone = phone.substring(1);
    phone = '+33' + phone;
  }

  return phone.replace('#', ',');
};
