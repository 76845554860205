<template>
  <v-container id="orders-in-review" fluid tag="section">
    <h2 class="mb-2">
      {{ $t('customerService.ordersInReview.subtitle') }}
    </h2>
    <div>{{ $t('customerService.ordersInReview.description') }}</div>
    <orders-view :header="false" :orders="orders" :last-update="lastUpdate" :error="error" :no-result-title="$t('order.list.empty.title')" :no-result-description="$t('order.list.empty.description')"
                 mode="list" :show-order-counter="false" @refresh="refreshOrders"
    />
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import OrdersView from '@/views/components/business/order/OrdersView';

  export default {
    name: 'OrdersInReview',
    components: { OrdersView },
    data () {
      return {
        error: '',
        lastUpdate: 0,
        orders: []
      };
    },
    methods: {
      async refreshOrders () {
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'orders/inreview');
          if (typeof response.data.orders === 'object') {
            this.orders = response.data.orders;
          } else {
            this.orders = [];
          }
          this.lastUpdate = Date.now();
          this.error = '';
        } catch (error) {
          this.error = await handleRequestError(error, this.$router, this.$i18n, this.$i18n.t('order.error'));
        }
        this.$emit('count', this.orders.length);
      }
    }
  };
</script>
